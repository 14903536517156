import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BasicSelect({
  data,
  onSelect,
  defaultValue,
  label,
  placeholder,
  ...props
}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl size="small" fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={defaultValue ? defaultValue : null}
          placeholder={label}
          label={label}
          onChange={onSelect}
        >
          {data ? (
            data.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={null}>No List Items</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
