import "../index.css";
import * as React from "react";
import { memo } from "react";
import { get } from "application/api";
import { useQuery } from "react-query";
import { addDepartment } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import { FeatureTable, Scaffold } from "components";

function Departments() {
  const store = useStateContext();
  const token = store.user.token;
  const orgID = store.user.organisation_id;

  const {
    isLoading,
    error,
    data: departments,
  } = useQuery("departments", () => get("departments", orgID, null, token));



  if (isLoading) {
    return <div>Loading...</div>;
  }

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Department name",
      width: 300,
      editable: true,
    },
    {
      field: "parent_id",
      headerName: "Parent ID",
      width: 90,
      editable: true,
    },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
  ];

  return (
    <Scaffold title="Departments">
      <FeatureTable rows={departments} columns={columns} />
    </Scaffold>
  );
}
export default memo(Departments);
