import jsPDF from "jspdf";
import { toPng } from "html-to-image";

export async function exportMultipleComponentsToPdf(className) {
  const doc = new jsPDF("p", "px");
  //console.log(className);
  const elements = document.getElementsByClassName(className);
  //console.log(elements);

  await creatPdf({ doc, elements });

  doc.save(className + `export.pdf`);
}

async function creatPdf({ doc, elements }) {
  const padding = 10;
  const marginTop = 20;
  let top = marginTop;

  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i);

    // Check if any descendant element should be ignored
    const descendants = el.querySelectorAll(".ignorePdfExport");
    descendants.forEach((descendant) => {
      descendant.style.display = "none";
    });

    const imgData = await toPng(el);

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;

    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding * 2;
      elWidth = elWidth * ratio - padding * 2;
    }

    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = marginTop;
    }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`);
    top += elHeight + marginTop;
  }
}
