import { AiOutlineCalendar } from "react-icons/ai";
import { BsKanban } from "react-icons/bs";
import { IoMdContacts } from "react-icons/io";
import { FaPeopleArrows } from "react-icons/fa";
import { GiLouvrePyramid, GiOrganigram } from "react-icons/gi";
import { GrScorecard, GrAchievement, GrUserWorker } from "react-icons/gr";
import { FiEdit, FiSettings, FiBookOpen, FiShoppingBag } from "react-icons/fi";

const membersData = [
  {
    MemberID: 1001,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1002,
    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1003,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1004,

    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1005,

    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1006,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1007,

    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",

    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1008,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1009,

    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1010,

    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1011,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1012,

    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",

    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1013,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1014,

    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1015,

    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1016,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1017,

    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",

    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1018,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1019,

    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1020,

    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1021,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1022,

    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",

    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1023,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1024,

    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1025,

    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1026,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1027,

    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",

    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1028,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1029,

    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1030,

    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1031,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1032,

    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",

    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1033,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1034,

    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1035,

    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1036,
    MemberName: "Nirav Joshi",
    MemberEmail: "nirav@gmail.com",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    ProjectName: "Basic Service Delivery",
    Status: "Active",
    StatusBg: "#8BE78B",
    Weeks: "40",
    Budget: "R2.4k",
    Location: "Durban",
  },
  {
    MemberID: 1037,

    MemberName: "Sunil Joshi",
    MemberEmail: "sunil@gmail.com",
    ProjectName: "Local Economic Development",
    Status: "Active",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",

    StatusBg: "#8BE78B",
    Weeks: "11",
    Budget: "R3.9k",
    Location: "Durban",
  },
  {
    MemberID: 1038,

    MemberName: "Andrew McDownland",
    MemberEmail: "andrew@gmail.com",
    ProjectName: "Financial Viability and Management",
    Status: "Pending",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#FEC90F",
    Weeks: "19",
    Budget: "R24.5k",
    Location: "CPT",
  },
  {
    MemberID: 1039,
    MemberName: "Christopher Jamil",
    MemberEmail: "jamil@gmail.com",
    ProjectName: "Good Governance, Public Participation",
    Status: "Completed",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "#8BE78B",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
  {
    MemberID: 1040,
    MemberName: "Michael",
    MemberEmail: "michael@gmail.com",
    ProjectName: "Institutional Transformation",
    Status: "Cancel",
    MemberImage: "src/assets/images/TasetiLogoIcon.svg",
    StatusBg: "red",
    Weeks: "34",
    Budget: "R16.5k",
    Location: "CPT",
  },
];

const idpData = [
  {
    id: 1,
    object_type: "KPA",
    name: "Good Governance",
    strategic_objective:
      "We must provide at least the minimum level of basic services to all households in its area of jurisdiction.",
    short_code: "KPA 1",
    performance_rating: 30,
    rating_fluctuation: -10,
    idp_id: 1,
    children: [
      {
        id: 1,
        object_type: "KFA",
        name: "Government Structures",
        operational_objective:
          "Optimally functioning government structures that are responsive to the needs of the community.",
        short_code: "KFA 1",
        performance_rating: 80,
        rating_fluctuation: -10,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Number of clinic upgrades",
            description:
              "Number of clinics upgraded to meet the National Core Standards",
            short_code: "KPI 1",
            performance_rating: 60,
            rating_fluctuation: 10,
            total_target: 10,
            calculation_method: "Percentage",
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 2,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Contraceptive prevalence rate",
            description: "Percentage of reproductive age on contraceptives",
            short_code: "KPI 2",
            performance_rating: 80,
            rating_fluctuation: 143,
            total_target: 50,
            calculation_method: "Number",
            annual_targets: [
              {
                year_1: 10,
                year_2: 10,
                year_3: 5,
                year_4: 15,
                year_5: 10,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 8,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        object_type: "KFA",
        name: "Risk Management",
        operational_objective:
          "A risk management system that is integrated into the IDP and Budget.",
        short_code: "KFA 2",
        performance_rating: 80,
        rating_fluctuation: 4,
        total_target: 100,
        calculation_method: "Percentage",
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Refuse Removed on time",
            short_code: "KPI 3",
            description: "Percentage of refuse removed on time",
            performance_rating: 60,
            rating_fluctuation: 10,
            total_target: 100,
            annual_targets: [
              {
                year_1: 100,
                year_2: 100,
                year_3: 100,
                year_4: 100,
                year_5: 100,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 80,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Budget spent on time",
            short_code: "KPI 4",
            description: "This is the description of KPI 4",
            performance_rating: 80,
            rating_fluctuation: 10,
            total_target: 100,
            calculation_method: "Number",
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 2,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
        ],
      },
    ],
    created_at: "2021-06-01T09:00:00.000000Z",
    updated_at: "2021-06-01T09:00:00.000000Z",
    created_by: 1,
  },
  {
    id: 2,
    object_type: "KPA",
    name: "Safe and Healthy Environments",
    strategic_objective:
      "Safe and healthy environments that are conducive to economic growth and development.",
    short_code: "KPA 2",
    performance_rating: 70,
    rating_fluctuation: 53,
    idp_id: 1,
    children: [
      {
        id: 1,
        object_type: "KFA",
        name: "Safety and Security",
        description: "Creating a safe and secure environment for all.",
        short_code: "KFA 3",
        performance_rating: 60,
        rating_fluctuation: 10,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Solved crimes",
            short_code: "KPI 5",
            description: "This is the description of KPI 5",
            total_target: 10,
            performance_rating: 60,
            rating_fluctuation: 10,
            calculation_method: "Percentage",
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 2,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Visible policing",
            description: "This is the description of kPI 6",
            total_target: 10,
            performance_rating: 80,
            rating_fluctuation: 10,
            short_code: "KPI 6",
            calculation_method: "Ratio",
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 2,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        object_type: "KFA",
        name: "Emergency Services",
        operational_objective: "Providing emergency services to all.",
        short_code: "KFA 4",
        performance_rating: 70,
        rating_fluctuation: -4,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Emergency Response time",
            short_code: "KPI 7",
            description: "This is the description of KPI 7",
            total_target: 10,
            performance_rating: 60,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 2,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Fire incidents",
            short_code: "KPI 8",
            description: "This is the description of KPI 8",
            total_target: 10,
            performance_rating: 80,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
            total_actual: 2,
            annual_actuals: [
              {
                year_1: 2,
                year_2: 0,
                year_3: 0,
                year_4: 0,
                year_5: 0,
              },
            ],
          },
        ],
      },
    ],
    created_at: "2021-06-01T09:00:00.000000Z",
    updated_at: "2021-06-01T09:00:00.000000Z",
    created_by: 1,
  },
  {
    id: 3,
    object_type: "KPA",
    name: "Community Development",
    strategic_objective: "Improving and maintaining municipal infrastructure.",
    short_code: "KPA 3",
    performance_rating: 85,
    rating_fluctuation: 10,
    idp_id: 1,
    children: [
      {
        id: 1,
        object_type: "KFA",
        name: "Parks and Recreation",
        operational_objective: "Providing parks and recreation facilities.",
        short_code: "KFA 5",
        performance_rating: 75,
        rating_fluctuation: 10,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Maintenance of parks",
            performance_rating: 80,
            rating_fluctuation: 10,
            description: "Percentage of parks maintained",
            short_code: "KPI_7",
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Sports facilities",
            description: "Percentage of sports facilities maintained",
            short_code: "KPI_8",
            performance_rating: 100,
            rating_fluctuation: 0,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        object_type: "KFA",
        name: "Events and Tourism",
        strategic_objective: "Hosting events and promoting tourism.",
        short_code: "KFA 6",
        performance_rating: 80,
        rating_fluctuation: 20,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Events hosted",
            operational_objective: "Number of events hosted",
            short_code: "KPI_5",
            performance_rating: 90,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Number of Tourists",
            operational_objective: "Number of tourists",
            short_code: "KPI_6",
            performance_rating: 80,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
        ],
      },
    ],
    created_at: "2021-06-01T09:00:00.000000Z",
    updated_at: "2021-06-01T09:00:00.000000Z",
    created_by: 1,
  },
  {
    id: 4,
    object_type: "KPA",
    name: "Instutional Development",
    strategic_objective: "Fostering a culture of learning and innovation.",
    short_code: "KPA 4",
    performance_rating: 90,
    rating_fluctuation: 10,
    idp_id: 1,
    children: [
      {
        id: 1,
        object_type: "KFA",
        name: "Human Resources and Skills Development",
        operational_objective: "Developing human resources and skills.",
        short_code: "KFA 7",
        performance_rating: 90,
        rating_fluctuation: 23,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Conflict resolution",
            short_code: "KPI 9",
            description: "This is the description of KPI 9",
            performance_rating: 90,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Up-skilling of staff",
            short_code: "KPI 10",
            description: "This is the description of KPI 10",
            performance_rating: 80,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        object_type: "KFA",
        name: "Monitoring and Evaluation, Performance Management",
        strategic_objective: "Monitoring and evaluating performance.",
        short_code: "KFA 8",
        performance_rating: 85,
        rating_fluctuation: 30,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "Performance reviews",
            short_code: "KPI 11",
            operational_objective: "This is the description of KPI 11",
            performance_rating: 90,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Performance management",
            operational_objective: "This is the description of KPI 12",
            short_code: "KPI 12",
            performance_rating: 80,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
                year_5: 2,
              },
            ],
          },
        ],
      },
    ],
    created_at: "2021-06-01T09:00:00.000000Z",
    updated_at: "2021-06-01T09:00:00.000000Z",
    created_by: 1,
  },
  {
    id: 5,
    object_type: "KPA",
    name: "Economic Development",
    strategic_objective:
      "Economic development that is inclusive and sustainable.",
    short_code: "KPA 5",
    performance_rating: 90,
    rating_fluctuation: 10,
    idp_id: 1,
    children: [
      {
        id: 1,
        object_type: "KFA",
        name: "Local Economic Development",
        operational_objective: "Developing local economic development.",
        short_code: "KFA 9",
        performance_rating: 90,
        rating_fluctuation: 23,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "SMMEs supported",
            short_code: "KPI 13",
            description: "This is the description of KPI 13",
            performance_rating: 90,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
                year_4: 3,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Jobs created",
            short_code: "KPI 14",
            description: "This is the description of KPI 14",
            performance_rating: 80,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        object_type: "KFA",
        name: "Infrastructure Development",
        operational_objective: "Developing infrastructure.",
        short_code: "KFA 10",
        performance_rating: 85,
        rating_fluctuation: 30,
        children: [
          {
            id: 1,
            object_type: "KPI",
            name: "New Infrastructure projects",
            short_code: "KPI 15",
            description: "This is the description of KPI 15",
            performance_rating: 90,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
              },
            ],
          },
          {
            id: 2,
            object_type: "KPI",
            name: "Existing Infrastructure projects",
            description: "This is the description of KPI 16",
            short_code: "KPI 16",
            performance_rating: 80,
            rating_fluctuation: 10,
            annual_targets: [
              {
                year_1: 2,
                year_2: 2,
                year_3: 1,
              },
            ],
          },
        ],
      },
    ],
    created_at: "2021-06-01T09:00:00.000000Z",
    updated_at: "2021-06-01T09:00:00.000000Z",
    created_by: 1,
  },
];

const sideBarLinks = [
  {
    title: "Dashboard",
    links: [
      {
        name: "dashboard",
        link: "dashboard",
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: "My organisation",
    links: [
      {
        name: "Departments",
        link: "departments",
        icon: <GiLouvrePyramid />,
      },
      {
        name: "Organogram",
        link: "Organogram",
        icon: <GiOrganigram />,
      },
    ],
  },
  {
    title: "Employees",
    links: [
      {
        name: "Employees",
        link: "employees",
        icon: <GrUserWorker />,
      },
      {
        name: "Senior Officials",
        link: "senior-officials",
        icon: <FaPeopleArrows />,
      },
      {
        name: "Setup",
        link: "setup-employees",
        icon: <FiSettings />,
      },
    ],
  },
  {
    title: "IDP",
    links: [
      {
        name: "Strategy",
        link: "strategy-idp",
        icon: <AiOutlineCalendar />,
      },
      {
        name: "Plan",
        link: "plan-idp",
        icon: <BsKanban />,
      },
      {
        name: "Monitor",
        link: "monitor-idp",
        icon: <FiEdit />,
      },
      {
        name: "Report",
        link: "report-idp",
        icon: <GrScorecard />,
      },
      {
        name: "Setup",
        link: "setup-idp",
        icon: <FiSettings />,
      },
    ],
  },
  {
    title: "SDBIP",
    links: [
      {
        name: "Strategy",
        link: "strategy-sdbip",
        icon: <AiOutlineCalendar />,
      },
      {
        name: "Plan",
        link: "plan-sdbip",
        icon: <BsKanban />,
      },
      {
        name: "Monitor",
        link: "monitor-sdbip",
        icon: <FiEdit />,
      },
      {
        name: "Report",
        link: "report-sdbip",
        icon: <GrScorecard />,
      },
      {
        name: "Setup",
        link: "setup-sdbip",
        icon: <FiSettings />,
      },
    ],
  },
  {
    title: "Settings",
    links: [
      {
        name: "Master Setup",
        link: "setup",
        icon: <FiSettings />,
      },
      {
        name: "Glossary",
        link: "glossary",
        icon: <FiBookOpen />,
      },
    ],
  },
];

const stackedChartData = [
  [
    { x: "Jan", y: 111.1 },
    { x: "Feb", y: 127.3 },
    { x: "Mar", y: 143.4 },
    { x: "Apr", y: 159.9 },
    { x: "May", y: 159.9 },
    { x: "Jun", y: 159.9 },
    { x: "July", y: 159.9 },
  ],
  [
    { x: "Jan", y: 111.1 },
    { x: "Feb", y: 127.3 },
    { x: "Mar", y: 143.4 },
    { x: "Apr", y: 159.9 },
    { x: "May", y: 159.9 },
    { x: "Jun", y: 159.9 },
    { x: "July", y: 159.9 },
  ],
];

const chartData = {
  stackedCustomSeries: [
    {
      dataSource: stackedChartData[0],
      xName: "x",
      yName: "y",
      name: "Budget",
      type: "StackingColumn",
      background: "blue",
    },

    {
      dataSource: stackedChartData[1],
      xName: "x",
      yName: "y",
      name: "Expense",
      type: "StackingColumn",
      background: "red",
    },
  ],

  stackedPrimaryXAxis: {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    labelIntersectAction: "Rotate45",
    valueType: "Category",
  },

  stackedPrimaryYAxis: {
    lineStyle: { width: 0 },
    minimum: 100,
    maximum: 400,
    interval: 100,
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: "{value}",
  },
  SparklineAreaData: [
    { x: 1, yval: 2 },
    { x: 2, yval: 6 },
    { x: 3, yval: 8 },
    { x: 4, yval: 5 },
    { x: 5, yval: 10 },
  ],
};

export { membersData, idpData, sideBarLinks, chartData };
