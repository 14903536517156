import React from "react";
import { Scaffold } from "components";

function Report() {
  return (
    <Scaffold moduleTitle="IDP" title="Report">
      Report
    </Scaffold>
  );
}

export default Report;
