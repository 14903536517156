import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useApiMutation } from "application/api";
import { useStateContext } from "application/store/ContextProvider";

const MonitorUpdateKPI = ({ kpi, setModalOpen }) => {
  const [currentValue, setCurrentValue] = useState(kpi.current_value);
  const { user } = useStateContext();

  const updateKpiMutation = useApiMutation(
    "kpis",
    "put",
    user.organisation_id,
    user.token,
    "active_sdbip",
    (response) => {
      alert("KPI successfully updated");
      setModalOpen(false);
    },
    (error) => {
      alert("Error adding KPI");
    }
  );

  const handleInputChange = (event) => {
    setCurrentValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    updateKpiMutation.mutate({
      ...kpi,
      current_value: currentValue,
    });

    console.log("Submitted with value:", currentValue);
  };
  return (
    <div className="h-fit w-[80vw] min-h-[50vh] flex-col bg-white flex justify-center p-10 px-10 rounded-md items-center">
      <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white text-xl bg-primary-blue font-light mb-8">
        Update <span className="font-semibold">{kpi.short_code}</span> Current
        Value
      </h2>
      <div className="mb-8 w-full flex justify-center items-center flex-col">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Current Value"
            type="number"
            variant="outlined"
            defaultValue={kpi.current_value}
            value={currentValue}
            onChange={handleInputChange}
          />
          <div className="flex mt-4 flex-row justify-between w-full">
            <div className="flex flex-col justify-end">
              <button
                type="button"
                onClick={() => setModalOpen(false)}
                className="text-gray-500 px-4 text-sm font-light hover:text-red-400"
              >
                Cancel
              </button>
            </div>
            <button
              type="submit"
              variant="contained"
              className="bg-primary-green text-white font-regular py-1 px-8 rounded hover:bg-primary-green-accent"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MonitorUpdateKPI;
