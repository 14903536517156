import React, { useState } from "react";
import { IMAGES } from "assets";
import { useStateContext } from "application/store/ContextProvider";
import { handleLogin } from "application/api";
import { TextField } from "@mui/material";
import './style.css'; 

const LoginPage = () => {
  const backgroundImageUrl = process.env.PUBLIC_URL + "taseti-bg-pattern.svg";
  const [showLoginForm, setShowLoginForm] = useState(false);
  const store = useStateContext();
  const [email, setEmail] = useState(null);
  const [orgCode, setOrgCode] = useState(null);
  const [password, setPassword] = useState(null);

  const showForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full overflow-hidden bg-primary-blue">
      {/* left div */}
      <div className="relative w-full lg:w-1/2 h-[50vh] lg:h-screen bg-primary-blue">
        <div
          className="flex items-center justify-center w-full h-full inset-0 bg-repeat bg-center"
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: "29%",
            backgroundPosition: "0 0",
          }}
        >
          <div className="w-[43.5%] h-[25%] lg:h-[13%] bg-secondary-blue lg:mb-[12%] ml-[1.5%] flex items-center justify-center">
            <img src={IMAGES.logoWhite} alt="logo" className="w-7/12" />
          </div>
        </div>
      </div>

      {/* right div */}
      <div className="relative flex flex-col w-full lg:w-1/2 h-[75vh] lg:h-screen bg-white">
        <div
          className={`relative flex items-center justify-center w-full ${
            showLoginForm ? "h-[90%]" : "h-[0] lg:h-[34%]"
          } bg-white transition-all duration-500`}
        >
          {showLoginForm && (
            <div className="w-[90%] lg:w-[55%] h-[90%] lg:h-[70%] bg-white rounded-xl shadow-2xl gap-8 p-8 flex flex-col">
              <span className="text-primary-blue font-medium text-[2.5rem]">
                Molo!
              </span>

              <TextField
                className="border border-[#868282] h-14 rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="OrganisationCode"
                type="text"
                label="Organisation Code"
                variant="outlined"
                onChange={(e) => setOrgCode(e.target.value)}
              />

              <TextField
                id="Email"
                type="text"
                variant="outlined"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                id="Password"
                type="password"
                label="Password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
              />

              <button
                className="bg-primary-green text-white font-medium text-lg h-14 z-50 w-full py-2 px-3 mt-2 shadow-md"
                onClick={() => handleLogin(email, orgCode, password, store)}
              >
                SIGN IN
              </button>
            </div>
          )}
        </div>
        <div
          className={`relative w-full ${
            showLoginForm ? "h-[10%]" : "h-[100%] lg:h-[66%]"
          } bg-primary-green flex flex-col items-center justify-center transition-all duration-500`}
        >
          <img
            src={IMAGES.heroImage}
            alt="hero"
            className={`absolute w-10/12 align-middle transform top-[-70px] lg:top-[-180px] `}
          />
          <h1
            className={`text-hero-mobile lg:text-hero font-black text-primary-blue leading-[110%] mt-12 ${
              showLoginForm ? "invisible" : ""
            }`}
          >
            GOVERNANCE <br /> MADE <span className={`text-white`}>SIMPLE.</span>
          </h1>
          <h2
            className={`text-hero-sub-mobile lg:text-hero-sub font-semibold text-primary-blue leading-[117%] text-center mt-8 ${
              showLoginForm ? "invisible" : ""
            }`}
          >
            A POWERFUL MANAGEMENT TOOL <br />
            TAILORED TO YOUR NEEDS.
          </h2>
          <button
            className={`bg-white text-primary-blue font-medium text-xl h-14 w-8/12 lg:w-1/3 uppercase py-2 px-3 mt-12 shadow-md ${
              showLoginForm ? "invisible" : ""
            }`}
            onClick={showForm}
          >
            login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
