import React from "react";
import { Scaffold, TabPager } from "components";
import { Metrics } from "./view_components";
import Align from "./view_components/tabs/SetupAlign";

const tabs = [
  {
    header: { text: "Metrics" },
    content: <Metrics />,
  },
  {
    header: { text: "Align" },
    content: <Align />,
  },

  {
    header: { text: "Financial Plan" },
    content: <div>Outline Financial Plan</div>,
  },
  {
    header: { text: "Glossary" },
    content: <div>Glossary</div>,
  },
  {
    header: { text: "User Permissions" },
    content: <div>User Permissions</div>,
  },
];

function SetupIDP() {
  return (
    <>
      <Scaffold moduleTitle="IDP" title="Setup" mb="">
        <TabPager content={tabs} />
      </Scaffold>
    </>
  );
}

export default SetupIDP;
