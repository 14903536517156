import React from "react";
import { ICONS } from "assets";

const ExpandButton = ({ isExpanded, setIsExpanded }) => {
  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };
  const IconArrow = ICONS.arrow;

  return (
    <div>
      <button
        className={`p-1 hover:bg-gray-200 m-1 rounded-md focus:outline-none transition-transform transform ${
          isExpanded ? "rotate-90" : ""
        }`}
        onClick={handleButtonClick}
      >
        {<IconArrow className="text-gray-500 dark:text-gray-400" size={15} />}
      </button>
    </div>
  );
};

export default ExpandButton;
