import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { Navbar, Sidebar } from "./components";
import {
  Dashboard,
  Departments,
  SeniorOfficials,
  Employees,
  Organogram,
  Landing,
  Login,
  ManageIDP,
  MonitorIDP,
  PlanIDP,
  ReportIDP,
  StrategyIDP,
  SetupIDP,
  ManageSDBIP,
  MonitorSDBIP,
  PlanSDBIP,
  ReportSDBIP,
  StrategySDBIP,
  Setup,
  PrivacyPolicy,
  SetupSDBIP,
  SetupEmployees,
} from "./views";

import { useStateContext } from "./application/store/ContextProvider";
import "./App.css";

const App = () => {
  const { activeMenu, isLoggedin, modalOpen, setModalOpen, modalContent } =
    useStateContext();

  return (
    <>
      <BrowserRouter>
        <div className="flex h-screen bottom-0 bg-main-bg fixed w-screen max-w-screen overflow-y-scroll dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4 z-10">
            <button
              type="button"
              className="text-3xl p-3 hover:drop-shadow-xl
                bg-primary-blue hover:bg-secondary-blue text-white absolute bottom-0 right-0"
              style={{ borderRadius: "50%" }}
            >
              <FiSettings />
            </button>
          </div>
          {isLoggedin && <Sidebar />}

          <div
            className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
              activeMenu && isLoggedin ? "md:w-[79vw] md:ml-[calc(18rem+5vh)]" : "flex-2"
            } transition-all duration-500`}
          >
            {isLoggedin && <Navbar />}
            {modalOpen && (
              <div className="fixed inset-0 z-50 w-full h-full flex items-center justify-center">
                <div className="absolute inset-0 bg-slate-600 opacity-50"></div>
                <div className="relative">{modalContent}</div>
              </div>
            )}

            <div>
              <Routes>
                <Route
                  path="/"
                  element={isLoggedin ? <Dashboard /> : <Login />}
                />
                <Route path="/landing" element={<Landing />} />

                <Route
                  path="/dashboard"
                  element={isLoggedin ? <Dashboard /> : <Login />}
                />

                {/* Privacy Policy */}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                {/* Views */}

                {/* Employees */}
                <Route
                  path="/senior-officials"
                  element={!isLoggedin ? <Login /> : <SeniorOfficials />}
                />
                <Route
                  path="/employees"
                  element={!isLoggedin ? <Login /> : <Employees />}
                />
                <Route
                  path="/setup-employees"
                  element={!isLoggedin ? <Login /> : <SetupEmployees />}
                />

                {/* My Organisation */}
                <Route
                  path="/departments"
                  element={!isLoggedin ? <Login /> : <Departments />}
                />
                <Route
                  path="/Organogram"
                  element={!isLoggedin ? <Login /> : <Organogram />}
                />

                {/* IDP */}
                <Route
                  path="/manage-idp"
                  element={!isLoggedin ? <Login /> : <ManageIDP />}
                />
                <Route
                  path="/monitor-idp"
                  element={!isLoggedin ? <Login /> : <MonitorIDP />}
                />
                <Route
                  path="/plan-idp"
                  element={!isLoggedin ? <Login /> : <PlanIDP />}
                />
                <Route
                  path="/report-idp"
                  element={!isLoggedin ? <Login /> : <ReportIDP />}
                />
                <Route
                  path="/strategy-idp"
                  element={!isLoggedin ? <Login /> : <StrategyIDP />}
                />
                <Route
                  path="/setup-idp"
                  element={!isLoggedin ? <Login /> : <SetupIDP />}
                />

                {/* SDBIP */}
                <Route
                  path="/manage-sdbip"
                  element={!isLoggedin ? <Login /> : <ManageSDBIP />}
                />
                <Route
                  path="/monitor-sdbip"
                  element={!isLoggedin ? <Login /> : <MonitorSDBIP />}
                />
                <Route
                  path="/plan-sdbip"
                  element={!isLoggedin ? <Login /> : <PlanSDBIP />}
                />
                <Route
                  path="/report-sdbip"
                  element={!isLoggedin ? <Login /> : <ReportSDBIP />}
                />
                <Route
                  path="/strategy-sdbip"
                  element={!isLoggedin ? <Login /> : <StrategySDBIP />}
                />
                <Route
                  path="/setup-sdbip"
                  element={!isLoggedin ? <Login /> : <SetupSDBIP />}
                />

                {/* Settings */}
                <Route
                  path="/setup"
                  element={!isLoggedin ? <Login /> : <Setup />}
                />

                {/* <Route path="*" element={<NotFound />} /> */}
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};

export default App;
