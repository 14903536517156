import { baseUrl } from "../utils/ENV";
import { sendRequest, useApiMutation } from "../utils/utils";

const get = async (endpoint, org_id, data, token) => {
  const url = `${baseUrl}/${endpoint}`;

  return sendRequest("get", url, token, org_id, data);
};

const create = async (endpoint, token, org_id, data) => {
  const url = `${baseUrl}/${endpoint}`;

  return sendRequest("post", url, token, org_id, data);
};

const update = async (endpoint, org_id, data, token) => {
  const url = `${baseUrl}/${endpoint}`;

  return sendRequest("put", url, token, org_id, data);
};

const apiDelete = async (token, org_id, idp_id) => {
  const url = `${baseUrl}/delete_idp`;

  const data = {
    idp_id,
  };

  return sendRequest("delete", url, token, org_id, data);
};

// KPAs

export { get, create, apiDelete, update };
