import React, { useState } from "react";
import { DropDown } from "components";
import { ExpandButton } from "components";

const ExpandableRowIDP = ({
  data,
  title,
  showTableHeader = false,
  setAddingObject,
  setModalOpen,
}) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const objectType = data.length > 0 ? data[0].object_type : null;
  let childObjectType;

  switch (objectType) {
    case "KPA":
      childObjectType = "KFA";
      break;
    case "KFA":
      childObjectType = "KPI";
      break;
    case "KPI":
      childObjectType = "PROJECT";
      break;
    default:
      childObjectType = false;
  }

  if (!data.length > 0 || childObjectType === "KPI") {
    childObjectType = false;
  }

  const handleAddNew = (childObjectType, entry) => {
    entry.childObjectType = childObjectType;
    setAddingObject(entry);
    setModalOpen(true);
    //console.log("add new", childObjectType);
  };

  const makeRatingColor = (rating) => {
    if (rating === null) return "bg-gray-300";
    if (rating >= 90) {
      return "bg-rating-green";
    } else if (rating >= 80) {
      return "bg-rating-yellow";
    } else if (rating >= 70) {
      return "bg-rating-orange";
    } else {
      return "bg-rating-red";
    }
  };

  const handleExpandToggle = (rowId) => {
    setExpandedRowIds((prevIds) =>
      prevIds.includes(rowId)
        ? prevIds.filter((id) => id !== rowId)
        : [...prevIds, rowId]
    );
  };

  return (
    <div className="flex flex-row m-0 p-0 w-full">
      <div className="w-full ml-4 bg-white">
        <table className="w-full">
          {showTableHeader && (
            <thead>
              <tr className="border-b">
                <th className=" text-xs font-thin text-gray-500 text-left">
                  Short Code
                </th>
                <th className=" text-xs font-thin text-gray-500 text-left">
                  Name
                </th>
                <th className=" text-xs font-thin text-gray-500 text-left">
                  Description
                </th>
                <th className=" text-xs font-thin text-gray-500 text-left">
                  Realtime Rating
                </th>
                <th className=" text-xs font-thin text-gray-500 text-left"></th>
              </tr>
            </thead>
          )}
          <tbody className="w-full">
            {data.length > 0 ? (
              data.map((entry) => {
                const ratingColor = makeRatingColor(entry.performance_rating);
                const isRowExpanded = expandedRowIds.includes(entry.id);

                return (
                  <React.Fragment key={entry.id}>
                    <tr className="hover:bg-gray-100 cursor-pointer w-full">
                      <td className="border-b w-40 text-xs">
                        <div className="flex flex-row justify-start">
                          {entry.children ? (
                            <ExpandButton
                              isExpanded={isRowExpanded}
                              setIsExpanded={() => handleExpandToggle(entry.id)}
                            />
                          ) : (
                            <div className="p-3">
                              <div className="w-1.5 h-1.5 rounded-full bg-gray-400"></div>
                            </div>
                          )}
                          <div className="flex flex-col justify-center ml-1">
                            {entry.short_code}
                          </div>
                        </div>
                      </td>
                      <td className="border-b w-40">
                        <h4 className="text-sm font-normal text-gray-900 dark:text-white">
                          {entry.name}
                        </h4>
                      </td>
                      <td className="border-b ">
                        <h4 className="text-sm font-normal text-gray-900 dark:text-white">
                          {entry.description ??
                            entry.strategic_objective ??
                            entry.operational_objective}
                        </h4>
                      </td>

                      <td
                        className={`border-b w-20 text-white text-xs font-bold ${ratingColor} text-center`}
                      >
                        {entry["performance_rating"]}
                        {entry["performance_rating"] ? "%" : "--"}
                      </td>
                      <td className="border-b w-10 text-center">
                        <DropDown
                          items={
                            childObjectType
                              ? [
                                  {
                                    text: "View",
                                    action: "view",
                                    onSelect: () => {},
                                  },
                                  {
                                    text: "Edit",
                                    action: "edit",
                                    onSelect: () => {},
                                  },
                                  {
                                    text: "Delete",
                                    action: "delete",
                                    onSelect: () => {},
                                  },
                                  {
                                    text: "Add new " + childObjectType,
                                    action: "add",
                                    onSelect: () =>
                                      handleAddNew(childObjectType, entry),
                                  },
                                ]
                              : [
                                  {
                                    text: "View",
                                    action: "view",
                                    onSelect: () => {},
                                  },
                                  {
                                    text: "Edit",
                                    action: "edit",
                                    onSelect: () => {},
                                  },
                                  {
                                    text: "Delete",
                                    action: "delete",
                                    onSelect: () => {},
                                  },
                                ]
                          }
                          className="rounded-full text-gray-500 dark:text-gray-400"
                        />
                      </td>
                    </tr>
                    {isRowExpanded && (
                      <tr className="col-span-5">
                        <td colSpan="5">
                          <div className="flex w-full flex-col">
                            <div className="flex flex-col w-full">
                              <ExpandableRowIDP
                                data={entry.children}
                                // title="IDP Objective"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <tr className="hover:bg-gray-100 cursor-pointer w-full">
                <td
                  colSpan={5}
                  className="border-b p-2 text-gray-500 text-xs text-center"
                >
                  No data found. Add new.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpandableRowIDP;
