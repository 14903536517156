import React, { useEffect, useRef } from "react";
import { getOrganisation } from "application/api";
import { Button, StackedChart, LineChart } from "components";
import { useStateContext } from "application/store/ContextProvider";
import { KPACard } from "./view_compents";
import { MOCK_API } from "assets";

const Dashboard = () => {
  const { activeMenu, brandColors } = useStateContext();
  const store = useStateContext();
  let organisation = useRef(null);
  const idpData = MOCK_API.idpData;

  const id = store.user.organisation_id;
  const token = store.user.token;
  //console.log("token", token);

  useEffect(() => {
    getOrganisation(token, id, store);
    organisation.current = store.organisation;
    return () => {
      organisation.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardStyles = `bg-white dark:text-gray-200
        dark:bg-secondary-dark-bg h-44 rounded-xl w-full
        md:p-8 pt-9 mt-9 bg-[url('/src/assets/images/ndebele-bg.svg')] bg-no-repeat 
        bg-cover bg-center shadow-md `;
  const cardStyles2 = `bg-[${brandColors.primary}] dark:text-gray-200
        dark:bg-secondary-dark-bg h-44 rounded-xl m-3 shadow-md`;
  return (
    <div className="mt-12 p-4">
      <div
        className="flex flex-wrap
      lg:flex-nowrap justify-center items-center"
      >
        <div
          className={cardStyles2 + (activeMenu ? "lg:w-[65vw]" : "lg:w-[80vw]")}
        >
          <div
            className={
              cardStyles + (activeMenu ? "lg:w-[65vw]" : "lg:w-[65vw]")
            }
          >
            <div className="flex justify-between items-center">
              <div>
                <div className="">
                  <div>
                    <p>Performance Rating</p>
                    <p className="text-2xl">73%</p>
                  </div>

                  <div className="mt-6 invisible md:visible">
                    <Button
                      color="white"
                      bgColor="#C4D82F"
                      text="Download Report"
                      borderRadius="6px"
                      size="md"
                    />
                  </div>
                </div>

                <div className="mt-6">
                  <div
                    className="flex m-1 mt-14 flex-wrap 
                gap-4 items-center"
                  >
                    {idpData.map((item) => (
                      <KPACard key={item.id} kpa={item} />
                    ))}
                  </div>
                </div>

                <div className="flex gap-10 flex-wrap mt-5 justify-center shadow-md">
                  <div
                    className="bg-white dark:text-gray-200  
                  dark:bg-secondary-dark-bg rounded-2xl md:w-full"
                  >
                    <div className="flex justify-between p-5">
                      <p className="font-semibold text-xl">Revenue Updates</p>
                      <div className="flex items-center gap-4">
                        <p
                          className="
                        text-gray-600 hover:drop-shadow-xl"
                        >
                          Expenses vs.
                          <span
                            className="
                        text-green-400 hover:drop-shadow-xl"
                          >
                            {" "}
                            Budget
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="mt-10 flex gap-10 flex-wrap justify-center">
                      <div className=" border-r-1 border-color m-4 pr-10">
                        <div>
                          <p>
                            <span className="text-2xl font-semibold">
                              R12 000 000 000
                            </span>
                            <span
                              className="p-1.5 hover:drop-shadow-xl
                          cursor-pointer rounded-full text-white bg-green-400
                          ml-3 text-xs"
                            >
                              23%
                            </span>
                          </p>
                          <p className="text-gray-500 mt-1">Budget</p>

                          <p className="mt-8">
                            <span className="text-2xl font-semibold">
                              R4 000 000 000
                            </span>
                          </p>
                          <p className="text-gray-500 mt-1">Expenses</p>
                        </div>

                        <div className="mt-10">
                          <Button
                            color={brandColors.white}
                            bgColor={brandColors.primary}
                            text="Download Report"
                            borderRadius="6px"
                          />
                        </div>
                      </div>
                      <div className="flex-col">
                        <StackedChart />
                        <div className="mt-5">
                          <LineChart />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
