import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { useStateContext } from "application/store/ContextProvider";

function Organization({ org, onCollapse, collapsed }) {
  const { modalOpen, setModalOpen } = useStateContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Card
      variant="outlined"
      style={{
        background: "white",
        display: "inline-block",
        borderRadius: 16,
      }}
    >
      <CardHeader
        avatar={
          <Tooltip
            title={`${_.size(org.children)} Sub Profile, ${_.size(
              org.account
            )} Sub Account`}
            arrow
          >
            <Badge
              style={{ cursor: "pointer" }}
              color="secondary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              showZero
              invisible={!collapsed}
              overlap="circle"
              badgeContent={_.size(org.children)}
              onClick={onCollapse}
            >
              <Avatar style={{ backgroundColor: "#ECECF4" }}>
                <BusinessIcon color="primary" />
              </Avatar>
            </Badge>
          </Tooltip>
        }
        title={org.name}
        action={
          <IconButton size="small" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
      />

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setModalOpen(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <BusinessIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Add Sub Department" />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountBalanceIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Edit Department" />
        </MenuItem>
      </Menu>
      <IconButton
        size="small"
        onClick={onCollapse}
        style={
          collapsed
            ? {
                transform: "rotate(0deg)",
                marginTop: -10,
                marginLeft: "auto",
              }
            : {
                transform: "rotate(180deg)",
                marginTop: -10,
                marginLeft: "auto",
              }
        }
      >
        <ExpandMoreIcon />
      </IconButton>
    </Card>
  );
}
function Account({ a }) {
  return (
    <Card
      variant="outlined"
      style={{
        cursor: "pointer",
        background: "white",
        display: "inline-block",
        borderRadius: 16,
      }}
    >
      <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: "#ECECF4" }}>
            <AccountBalanceIcon color="secondary" />
          </Avatar>
        }
        title={a.name}
      />
    </Card>
  );
}
function Product({ p }) {
  //   const classes = useStyles();
  return (
    <Card
      variant="outlined"
      style={{
        background: "white",
        display: "inline-block",
        borderRadius: 16,
      }}
    >
      <CardContent>
        <Typography variant="subtitle2">{p.name}</Typography>
      </CardContent>
    </Card>
  );
}
function Node({ o, parent }) {
  const [collapsed, setCollapsed] = React.useState(o.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  React.useEffect(() => {
    o.collapsed = collapsed;
  });
  const T = parent
    ? TreeNode
    : (props) => (
        <Tree
          {...props}
          lineWidth={"2px"}
          lineColor={"#bbc"}
          lineBorderRadius={"12px"}
        >
          {props.children}
        </Tree>
      );
  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {_.map(o.account, (a) => (
        <TreeNode label={<Account a={a} />}>
          <TreeNode label={<Product p={a.product} />} />
        </TreeNode>
      ))}
      {_.map(o.children, (c) => (
        <Node o={c} parent={o} />
      ))}
    </T>
  );
}

export default function Chart({ data }) {
  return (
    <Box bgcolor="background" padding={4} height="80vh">
      <Node o={data} />
    </Box>
  );
}
