import React from "react";
import { Scaffold, TabPager } from "components";
import { ScoreCards } from "./view_components";
import TabTest from "components/TabTest";
const tabs = [
  {
    header: { text: "Score Cards" },
    content: <ScoreCards />,
  },
  {
    header: { text: "Glossary" },
    content: <div>Glossary</div>,
  },
];

function Plan() {
  return (
    <Scaffold moduleTitle="SDBIP" title="Plan"  mb="">
      {/* <TabPager content={tabs} /> */}
      <TabTest content={tabs} />
    </Scaffold>
  );
}

export default Plan;
