import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useStateContext } from "application/store/ContextProvider";
import { calculateRatingColor } from "application/utils";
import MonitorUpdateKPI from "../modals/MonitorUpdateKPI";

const MonitorIndicatorsTable = ({ idpData, fitView = false }) => {
  const { kpas } = idpData;
  console.log("kpas", kpas);
  const {
    brandPrimaryColors,
    brandColors,
    ratingColors,
    setModalOpen,
    setModalContent,
  } = useStateContext();
  const tableStyle = fitView
    ? { borderRadius: 0, width: "100%" }
    : { borderRadius: 0 };

  const headerCellStyle = {
    backgroundColor: brandPrimaryColors.blue ?? "#000",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#fff",
    p: 2,
  };

  if (!kpas) {
    return <div>Loading...</div>;
  }
  function prepareIndicatorsData({ kpas }) {
    const indicatorsData = [];
    kpas.forEach((kpa) => {
      const { children } = kpa;
      if (!children) {
        console.log("No children");
        return;
      }
      children.forEach((kfa) => {
        const { children } = kfa;
        if (!children) {
          console.log("No children");
          return;
        }

        children.forEach((indicator) => {
          const bgColor = calculateRatingColor(
            indicator.current_value,
            indicator.annual_target,
            ratingColors
          );

          console.log("bgColor", bgColor);

          indicatorsData.push({
            kfa_short_code: kfa.short_code,
            kpa_short_code: kpa.short_code,
            bgColor,
            ...indicator,
          });
        });
      });
    });
    return indicatorsData;
  }
  const headings = [
    "KPA",
    "KFA",
    "Key Performance Indicator",
    "Base",
    "Annual",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "Current Value",
  ];
  const indicatorsData = prepareIndicatorsData({ kpas });
  console.log("indicators", indicatorsData);
  return (
    <div className="mt-8">
      <TableContainer sx={{ overflow: "scroll" }}>
        <Table sx={tableStyle} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={3}
                sx={{
                  ...headerCellStyle,
                  border: 0.5,
                  borderColor: brandColors.lightGray,
                }}
              />
              <TableCell
                colSpan={7}
                align="center"
                sx={{
                  ...headerCellStyle,
                  border: 0.5,
                  borderColor: brandColors.lightGray,
                }}
              >
                Targets
              </TableCell>
            </TableRow>
            <TableRow>
              {headings.map((heading) => (
                <TableCell
                  align="center"
                  colSpan={1}
                  sx={{
                    ...headerCellStyle,
                    border: 0.5,
                    borderColor: brandColors.lightGray,
                  }}
                >
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderRight: 1, borderColor: "inherit" }}>
            {indicatorsData.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                >
                  {row.kpa_short_code}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                >
                  {row.kfa_short_code}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="center">{row.baseline}</TableCell>
                <TableCell align="center">{row.annual_target}</TableCell>
                <TableCell align="center">{row.quarter_1_target}</TableCell>
                <TableCell align="center">{row.quarter_2_target}</TableCell>
                <TableCell align="center">{row.quarter_3_target}</TableCell>
                <TableCell align="center">{row.quarter_4_target}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: row.bgColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalContent(null);
                    setModalOpen(true);
                    setModalContent(
                      <MonitorUpdateKPI kpi={row} setModalOpen={setModalOpen} />
                    );
                  }}
                >
                  {row.performance_rating}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MonitorIndicatorsTable;
