import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStateContext } from "application/store/ContextProvider";

export default function PlanKpaKfaTablePlanKpaKfaTable({
  headings,
  rowData,
  rowKeys,
  fitView = false,
  noDataText = "No Entry Found.",
}) {
  const { brandColors } = useStateContext();

  const tableStyle = fitView
    ? { maxWidth: "100%", borderRadius: 0 }
    : { borderRadius: 0 };

  const headerCellStyle = {
    backgroundColor: brandColors.blue ?? "#000",
    fontWeight: "bold",
    fontSize: "1rem",
    color: "#fff",
  };

  const cellStyle = {
    borderRight: 1,
    borderColor: "inherit",
    maxWidth: 100,
  };

  return (
    <TableContainer component={Paper} sx={tableStyle}>
      <Table sx={tableStyle} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headings ? (
              headings.map((heading) => (
                <TableCell align="left" sx={headerCellStyle}>
                  {heading}
                </TableCell>
              ))
            ) : (
              <TableCell align="left">No Data</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData && rowData.length > 0 ? (
            rowData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}
              >
                {rowKeys.map((key) => (
                  <TableCell align="left" sx={cellStyle}>
                    {renderCellValue(key, row, noDataText)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={2}>
                {noDataText}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function renderCellValue(key, row, noDataText) {
  switch (key.type) {
    case "string":
      return key.name === "name"
        ? `${row.short_code}: ${row[key.name]}`
        : row[key.name];
    case "array":
      return row[key.name]
        ? row[key.name].map((child, index) => (
            <div className="border-b" key={index}>
              {child.short_code ?? null}: {child.name}
            </div>
          ))
        : noDataText;
    case "child_array":
      return row[key.parent_name]
        ? row[key.parent_name].map((child, index) => (
            <div className="border-b" key={index}>
              {child[key.name]}
            </div>
          ))
        : noDataText;
    default:
      return noDataText;
  }
}
