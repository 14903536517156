import React, { useState } from "react";
import { create, getFinancialYears, useQueryClientData } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import { TextField } from "@mui/material";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { SelectMulti } from "components";

const CreateIDP = ({ setModalOpen }) => {
  const store = useStateContext();
  const token = store.user.token;
  const orgID = store.organisation.id;
  const [idpName, setIdpName] = useState("Auto-generated");

  const idps = useQueryClientData("idps");

  const {
    isLoading,
    error,
    data: finYearData,
  } = useQuery("financial_years", () => getFinancialYears(token, orgID, store));

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    short_code: "IDP " + (idps.length + 1),
    financial_year_ids: [],
  });

  const queryClient = useQueryClient();
  // IDP Mutation
  const addIdpMutation = useMutation(
    () => create("idps", token, orgID, formData, store),
    {
      onSuccess: (response) => {
        if (response.message) {
          alert(
            "Error creating IDP Make sure all fields are filled and you have selected at least 5 financial years"
          );
          return;
        }
        queryClient.prefetchQuery("idps");
        alert("IDP created successfully");
        setModalOpen(false);
      },
      onError: (error) => {
        alert("Error creating IDP");
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    addIdpMutation.mutate({ ...formData });
  };

  // Function to handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMultiSelectChange = (idsArray) => {
    const idpName = makeIdpName(idsArray);
    setIdpName(idpName);
    setFormData({
      ...formData,
      name: idpName,
      financial_year_ids: idsArray,
    });
  };

  const makeIdpName = (idArray) => {
    let selectedFinYears = finYearData.filter((item) => {
      return idArray.includes(item.id);
    });
    let finYearNames = [];
    selectedFinYears.map((item) => {
      finYearNames.push(...item.name.split("-"));
      return null;
    });
    finYearNames.sort();
    return finYearNames[0] + "-" + finYearNames.at(-1);
  };

  return (
    <div className=" p-10 pb-6 text-left bg-white rounded-md shadow-xl">
      <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white  text-xl bg-primary-blue font-light mb-8">
        Create new <span className="font-semibold">IDP</span>
      </h2>
      <form className="mt-16" onSubmit={handleSubmit}>
        {/* Form fields go here */}
        <div className="flex flex-row gap-10 justify-between">
          <div className="mb-8 md:flex-3">
            <TextField
              type="text"
              id="name"
              disabled
              name="name"
              label="IDP Name"
              variant="outlined"
              value={idpName}
              onChange={handleChange}
              className="w-full p-2 border-2 rounded-md"
              required
            />
          </div>
          <div className="mb-8 md:flex-1">
            <TextField
              type="text"
              id="short_code"
              name="short_code"
              label="Short Code"
              variant="outlined"
              value={formData.short_code}
              onChange={handleChange}
              className="w-full p-2 border-2 rounded-md"
              required
            />
          </div>
        </div>
        <div className="mb-8 w-full">
          <TextField
            type="text"
            sx={{ width: "100%" }}
            id="description"
            name="description"
            value={formData.description}
            label="Description"
            variant="outlined"
            onChange={handleChange}
          />
        </div>

        {finYearData && (
          <div className="mb-8 w-full">
            <SelectMulti
              name="financial_years"
              data={finYearData}
              formData={formData}
              setFormData={setFormData}
              makeIdpName={makeIdpName}
              setIdpName={setIdpName}
              handleChange={handleMultiSelectChange}
            />
          </div>
        )}

        {/* Submit button */}
        <div className="flex mt-4 flex-row justify-between">
          <div className="flex flex-col justify-end">
            <button
              type="button"
              onClick={() => setModalOpen(false)}
              className="text-gray-500 px-4 text-sm font-light hover:text-red-400"
            >
              Cancel
            </button>
          </div>
          <button
            type="submit"
            className="bg-primary-green text-white font-regular py-1 px-8 rounded hover:bg-primary-green-accent"
          >
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateIDP;
