import axios from "axios";
import { baseUrl } from "./ENV";
import { useMutation, useQueryClient } from "react-query";

const useApiMutation = (
  endpoint,
  method,
  org_id,
  token,
  queryKey,
  onSuccess = () => {},
  onError = () => {}
) => {
  const queryClient = useQueryClient();

  const mutate = useMutation(
    (data) =>
      sendRequest(method, baseUrl + "/" + endpoint, token, org_id, data),
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries(queryKey);
        onSuccess(response, variables);
      },
      onError: (error) => {
        onError(error);
      },
    }
  );

  return { ...mutate };
};

export default useApiMutation;

const api = axios.create({
  baseURL: baseUrl,
});

const useQueryClientData = (key) => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(key);
};

const sendRequest = async (method, url, token, org_id, data) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const params = {
    id: org_id,
    ...data,
  };

  try {
    const response = await api({
      method,
      url,
      params,
      data,
      headers,
    });

    if (response.status === 200) {
      return response.data.data;
    }

    return response;
  } catch (error) {
    //console.log(error);
    return error;
  }
};

export { sendRequest, useQueryClientData, useApiMutation };
