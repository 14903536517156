import React, { useState } from "react";
import { useStateContext } from "application/store/ContextProvider";
import { get } from "application/api";
import { Scaffold } from "components";
import { useQuery } from "react-query";
import OrgChart from "./view_components/components/Organogram";
import { Modal } from "components/index";
import CreateDepartment from "./view_components/modals/CreateDepartment";

const Organogram = () => {
  const store = useStateContext();
  const user = store.user;
  const token = user.token;
  const orgID = user.organisation_id;
  const { modalOpen, setModalOpen, setModalContent } = useStateContext();

  const {
    isLoading,
    error,
    data: departments,
  } = useQuery("departments", () =>
    get("departments", orgID, { getTree: true }, token)
  );

  if (isLoading) {
    setModalContent(null);
    return (
      <Scaffold title={"Organogram"}>
        <div>Loading...</div>
      </Scaffold>
    );
  }
  if (error) {
    return (
      <Scaffold title={"Organogram"}>
        <div>An error has occurred: {error.message}</div>
      </Scaffold>
    );
  }
  // //console.log(departments);
  return (
    <>
      {/* <Scaffold title={"Organogram"}> */}
      <OrgChart data={departments[0]} />
      {/* </Scaffold> */}
      <Modal modalOpen={modalOpen}>
        <CreateDepartment setModalOpen={setModalOpen} />
      </Modal>
    </>
  );
};

export default Organogram;
