import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";

export default function LineGraph() {
  return (
    <LineChart
      xAxis={[
        {
          id: "month",
          scaleType: "point",
          data: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
        },
      ]}
      series={[
        {
          data: [2, 3, 1, 4, 5, null, null, null, null, null, null, null],
          valueFormatter: (value) => (value == null ? "NaN" : value.toString()),
        },
        {
          data: [3, 1, 4, 2, 1],
          valueFormatter: (value) => (value == null ? "NaN" : value.toString()),
        },
        {
          data: [6, 5, 6, 5, 7],
          valueFormatter: (value) => (value == null ? "?" : value.toString()),
        },
      ]}
      height={200}
      width={500}
      margin={{ top: 10, bottom: 20 }}
    />
  );
}
