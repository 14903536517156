import React, { useEffect, useState } from "react";
import { useStateContext } from "application/store/ContextProvider";
import { create } from "application/api";

function CreateIdpObject({ setModalOpen, parentObject }) {
  const store = useStateContext();
  const organisation_id = store.user.organisation_id;
  const token = store.user.token;

  const [objectName, setObjectName] = useState("");
  const [objective, setObjective] = useState("");
  const [objectShortCode, setObjectShortCode] = useState("");

  const [formData, setFormData] = useState({
    name: null,
    short_code: objectShortCode,
    parent_id: parentObject.id,
    idp_id: parentObject.idp_id ?? parentObject.id,
  });

  useEffect(() => {
    makeObjectShortCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeObjectShortCode = () => {
    let num = parentObject.children ? parentObject.children.length + 1 : 1;
    const shortCode = `${parentObject.childObjectType} ${num}`;
    setObjectShortCode(shortCode);
    setFormData({ ...formData, short_code: shortCode });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "name") {
      setObjectName(value);
    }

    if (name === objectiveName.id) {
      setObjective(value);
    }

    if (name === "short_code") {
      setObjectShortCode(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const objectType = parentObject.childObjectType;
    create(`${objectType}s`, token, organisation_id, formData, store).then(
      (response) => {
        //console.log("response", response);
        if (response.message) {
          alert(
            "Error creating. Please check if the name or short code already exists."
          );
          return;
        }
        alert("Created successfully");
        setModalOpen(false);
      }
    );
  };

  const objectiveName =
    parentObject.childObjectType === "KFA" ||
    parentObject.childObjectType === "KPI"
      ? { text: "Operational Objective", id: "operational_objective" }
      : { text: "Strategic Objective", id: "strategic_objective" };

  return (
    <div className="w-[40rem] p-6 pb-2 text-left bg-white rounded-md shadow-xl">
      <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white  text-xl bg-primary-blue font-light mb-8">
        Add <span className="font-semibold">{parentObject.name}</span>{" "}
        {parentObject.childObjectType}
      </h2>
      <form className="mt-16" onSubmit={handleSubmit}>
        <div className="flex flex-row gap-16 justify-between">
          <div className="mb-8 flex-1">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-regular mb-1"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={objectName}
              onChange={handleChange}
              className="w-full p-2 border-2 rounded-md"
              required
            />
          </div>
          <div className="mb-8 flex-3">
            <label
              htmlFor="short_code"
              className="block text-gray-700 text-sm font-regular mb-1 flex-1"
            >
              Short Code
            </label>
            <input
              type="text"
              id="short_code"
              name="short_code"
              value={objectShortCode}
              onChange={handleChange}
              className="w-full p-2 border-2 rounded-md"
              required
            />
          </div>
        </div>
        <div className="mb-8">
          <label
            htmlFor={objectiveName.id}
            className="block text-gray-700 text-sm font-regular mb-1"
          >
            {objectiveName.text}
          </label>
          <textarea
            type="text"
            id={objectiveName.id}
            name={objectiveName.id}
            value={objective}
            onChange={handleChange}
            className="w-full p-2 min-h-24 border-2 rounded-md"
            required
          />
        </div>
        <div className="mb-8">
          <input
            type="hidden"
            id="parent_id"
            name="parent_id"
            value={parentObject.id}
            className="w-full p-2 border rounded-md"
            required
          />
        </div>

        <div className="flex flex-row p-4 justify-between">
          <div className="flex flex-col justify-end">
            <button
              type="button"
              onClick={() => setModalOpen(false)}
              className="text-gray-500 px-4 text-sm font-light hover:text-red-400"
            >
              Cancel
            </button>
          </div>
          <button
            type="submit"
            className="bg-primary-green text-white font-regular py-1 px-8 rounded hover:bg-primary-green-accent"
          >
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateIdpObject;
