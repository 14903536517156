import * as React from "react";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Slider from "@mui/material/Slider";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import { PieChart } from "@mui/x-charts/PieChart";
// import { legendClasses } from "@mui/x-charts/ChartsLegend";

const data1 = [{ label: "KPA 1", value: 1 }];

const data2 = [
  { label: "KFA 1", value: 100 },
  { label: "KFA 2", value: 100 },
  { label: "KFA 3", value: 100 },
  { label: "KFA 4", value: 100 },
];

export default function PieAnimation() {
  const [radius, setRadius] = React.useState(76);
  const [itemNb, setItemNb] = React.useState(5);
  //   const [skipAnimation, setSkipAnimation] = React.useState(false);

  //   const handleItemNbChange = (event, newValue) => {
  //     if (typeof newValue !== "number") {
  //       return;
  //     }
  //     setItemNb(newValue);
  //   };
  //   const handleRadius = (event, newValue) => {
  //     if (typeof newValue !== "number") {
  //       return;
  //     }
  //     setRadius(newValue);
  //   };

  return (
    <Box sx={{ width: "100%", color: "white" }}>
      <PieChart
        height={300}
        sx={{ color: "white" }}
        series={[
          {
            data: data1,
            outerRadius: radius,
            arcLabel: (params) => params.label ?? "",
          },
          {
            data: data2.slice(0, itemNb),
            innerRadius: radius,
            arcLabel: (params) => params.label ?? "",
          },
        ]}
        // skipAnimation={skipAnimation}
        tooltip={false}
        title="Pie Chart"
        slotProps={{
          legend: { hidden: true },
          tooltip: { hidden: true },
        }}
      />
    </Box>
  );
}

//   <FormControlLabel
//     checked={skipAnimation}
//     control={
//       <Checkbox
//         onChange={(event) => setSkipAnimation(event.target.checked)}
//       />
//     }
//     label="skipAnimation"
//     labelPlacement="end"
//   />
//   <Typography id="input-item-number" gutterBottom>
//     Number of items
//   </Typography>
//   <Slider
//     value={itemNb}
//     onChange={handleItemNbChange}
//     valueLabelDisplay="auto"
//     min={1}
//     max={10}
//     aria-labelledby="input-item-number"
//   />
//   <Typography id="input-radius" gutterBottom>
//     Radius
//   </Typography>
//   <Slider
//     value={radius}
//     onChange={handleRadius}
//     valueLabelDisplay="auto"
//     min={15}
//     max={100}
//     aria-labelledby="input-radius"
//   />
