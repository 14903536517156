import calendarImg from "./images/calendar.svg";
import clockImg from "./images/clock.svg";
import iconLogo from "./images/TasetiLogoIcon.svg";
import logo from "./images/taseti.png";
import heroImage from "./images/hero-image.png";
import mfundo from "./images/avatar.jpeg";
import logoWhite from "./images/TasetiLogoWhite.svg";
import { membersData, idpData, sideBarLinks, chartData } from "./mockData";

// Icons
import { FaCaretRight } from "react-icons/fa6";
import { BsGraphUpArrow, BsGraphDownArrow } from "react-icons/bs";
import { LuBarChartHorizontal, LuBarChart3 } from "react-icons/lu";
import { MdCancelPresentation } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { RiSave2Line } from "react-icons/ri";
import { BsPlus } from "react-icons/bs";
import { SlOptions, SlOptionsVertical } from "react-icons/sl";

const IMAGES = {
  calendarImg,
  clockImg,
  iconLogo,
  logo,
  logoWhite,
  heroImage,
  mfundo,
};

const ICONS = {
  arrow: FaCaretRight,
  graphUp: BsGraphUpArrow,
  graphEqual: LuBarChartHorizontal,
  graphFluctuate: LuBarChart3,
  graphDown: BsGraphDownArrow,
  edit: CiEdit,
  cancel: MdCancelPresentation,
  save: RiSave2Line,
  add: BsPlus,
  optionsHorizontal: SlOptions,
  optionsVertical: SlOptionsVertical,
};

const MOCK_API = { membersData, idpData, sideBarLinks, chartData };

export { IMAGES, ICONS, MOCK_API };
