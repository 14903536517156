import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStateContext } from "application/store/ContextProvider";
import { SelectMulti } from "components/index";
import { useQuery } from "react-query";
import { get, update } from "application/api";
import { Button } from "@mui/material";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";

function Row(props) {
  const { row } = props;
  const { showAlignInput } = props;
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (data) => update("policies/kpas", user.organisation_id, data, user.token),
    {
      onSuccess: (response) => {
        queryClient.prefetchQuery("kpas");
        alert("KPAs successfully aligned to policy");
      },
      onError: (error) => {
        alert("Error Aligning KPAs to Policy");
      },
    }
  );

  const { user } = useStateContext();

  const {
    isLoading,
    error,
    data: kpaData,
    status,
  } = useQuery("kpas", () =>
    get("kpas", user.organisation_id, { idp_id: 1 }, user.token)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ width: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="h7" fontWeight={700}>
            {row.metric_name}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h7" fontWeight={500}>
                {/* {row.objectives_name}: */}
              </Typography>
              <Table size="small" aria-label="objectives">
                <TableBody>
                  {row.children.map((child) => (
                    <TableRow key={child.name}>
                      <TableCell component="th" scope="row">
                        {child.short_code}
                        {": "}
                        {child.name}
                      </TableCell>
                      {showAlignInput ? (
                        <TableCell align="right" width="50%">
                          <SelectMulti
                            optionsName="KPAs to Align"
                            data={kpaData}
                            defaultValue={child.aligned_kpas ?? null}
                            handleChange={(idsArray) => {
                              setFormData({
                                ...formData,
                                [row.id]: {
                                  ...formData[row.id],
                                  [child.id]: idsArray,
                                },
                              });
                            }}
                          />
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                  {showAlignInput ? (
                    <TableRow>
                      <TableCell
                        component="th"
                        align="right"
                        colSpan={2}
                        scope="row"
                      >
                        <Button
                          onClick={() => {
                            const data = {
                              parent_id: row.id,
                              parent_object_type: row.object_type,
                              alignment_data: formData[row.id],
                            };
                            mutate(data);
                          }}
                        >
                          Save
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_code: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    parent_id: PropTypes.number.isRequired,
    idp_id: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable({ data, showAlignInput = false }) {
  const { brandColors } = useStateContext();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              colSpan={6}
              style={{ backgroundColor: brandColors.blue, color: "white" }}
            >
              <Typography variant="h6">{data.name}</Typography>
            </TableCell>
            {/* <TableCell /> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            style={{ height: 20 }}
            row={data}
            showAlignInput={showAlignInput}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
