import React from "react";
import MonitorIndicatorsTable from "../components/MonitorIndicatorsTable";
import { useQuery } from "react-query";
import { get } from "application/api";
import { useStateContext } from "application/store/ContextProvider";

const MonitorIndicators = () => {
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", user.organisation_id, null, user.token)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  return (
    <div>{activeIDP && <MonitorIndicatorsTable idpData={activeIDP} />}</div>
  );
};

export default MonitorIndicators;
