import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import Avatar from "@mui/material/Avatar";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStateContext } from "application/store/ContextProvider";



function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        
        style={{
          "&:hover": {
            backgroundColor: "#F3F4F6",
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.last_name}</TableCell>
        <TableCell align="left">{row.role_name}</TableCell>
        <TableCell align="left">{row.department_name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableContainer sx={{ padding: 2 }}>
                <Table size="small" style={{ width: "100%" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        rowSpan={4}
                        style={{ padding: 0, border: "none" }}
                        align="center"
                      >
                        <div className="flex justify-center items-center">
                          <Avatar
                            className="photo"
                            src={row.img_url}
                            alt={row.id}
                            sx={{
                              width: 150,
                              height: 150,
                              alignSelf: "center",
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          First Name:
                        </Typography>{" "}
                        {row.title} {row.name}
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          Last Name:
                        </Typography>{" "}
                        {row.last_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          Address:
                        </Typography>{" "}
                        {row.address_line_1}
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          Address line 2:
                        </Typography>{" "}
                        {row.address_line_2}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          City:
                        </Typography>{" "}
                        {row.city}
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          Postal Code:
                        </Typography>{" "}
                        {row.postal_zip_code}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          Phone:
                        </Typography>{" "}
                        {row.mobile}
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={500} variant="h7">
                          HireDate:
                        </Typography>{" "}
                        {new Date(row.employment_date).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
  }).isRequired,
};

export default function SeniorsTable({ metric, objective, data }) {
  const { brandColors } = useStateContext();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align="left">Last Name</TableCell>
            <TableCell align="left">Position</TableCell>
            <TableCell align="left">Department</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
