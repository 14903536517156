import React from "react";
import { TextField, Autocomplete } from "@mui/material";

const MultiSelect = ({
  data,
  handleChange,
  defaultValue,
  maxValues,
  limitTags,
  ...props
}) => {
  // handle the change event

  return (
    <Autocomplete
      sx={{ width: "100%", maxWidth: 500 }}
      multiple
      options={data}
      defaultValue={defaultValue}
      limitTags={limitTags}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      onChange={(event, newValue) => {
        const idsArray = newValue.map((item) => item.id);
        handleChange(idsArray);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={`Select ${props.optionsName ?? "Financial Years"}`}
          placeholder={`Select ${props.optionsName ?? "Financial Years"}`}
        />
      )}
    />
  );
};

export default MultiSelect;
