import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useStateContext } from "application/store/ContextProvider";
// import { serratedTabsStylesHook } from "@mui-treasury/styles/tabs";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabTest({ content }) {
  const [value, setValue] = React.useState(0);
  const { brandColors } = useStateContext();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", p: 0 }}>
      <Box
        sx={{
          width: "100%",
          color: brandColors.purple,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="inherit"
          sx={{ py: 0, height: 45 }}
          TabIndicatorProps={{
            style: {
              backgroundColor: brandColors.purple,
            },
          }}
          aria-label="basic tabs example"
        >
          {content.map((item, index) => (
            <Tab
              label={item.header.text}
              {...a11yProps(index)}
              disableRipple
              sx={{
                pb: 0,
                fontSize: "13px",
                fontFamily: "Roboto",
                letterSpacing: 0,
              }}
            />
          ))}
        </Tabs>
      </Box>
      {content.map((item, index) => (
        <CustomTabPanel value={value} index={index}>
          {item.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
