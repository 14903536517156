import React, { useState } from "react";
import { ExpandButton } from "components";
import ExpandableRowIDP from "./SetupIDPBoxRow";
import { Modal } from "components";
import { CreateIdpObject } from "..";
import { DropDown } from "components";

const IDPSetupBox = ({ idp }) => {
  const [isSetupBoxExpanded, setIsSetupBoxExpanded] = useState(false);
  let kpas = idp.children ?? false;
  const [modalOpen, setModalOpen] = useState(false);
  const [addingObject, setAddingObject] = useState(false);

  return (
    <div className="pdfExportIDP">
      <div
        className={`w-full max-h-fit bg-white border p-4 pb-8 border-gray-200 rounded-md overflow-hidden transition-height duration-800 transform`}
      >
        <div className="flex flex-row mb-0 justify-between items-end">
          <div className="flex flex-row mb-4 md:w-10/12 justify-start ">
            <ExpandButton
              isExpanded={isSetupBoxExpanded}
              setIsExpanded={setIsSetupBoxExpanded}
            />
            <div className="flex flex-col justify-start ml-1">
              <h4 className="mb-1 text-base text-left font-regular text-gray-900 dark:text-white">
                {idp.name}
              </h4>
              <span className="text-sm text-left w-8/12 text-gray-500 dark:text-gray-400">
                {idp.description}
              </span>
            </div>
          </div>
          <div className="flex flex-col w-8/12 h-1/3 md:h-auto md:w-4/12 items-end justify-center">
            <DropDown
              items={[
                {
                  text: "View",
                  action: "view",
                  onSelect: () => {},
                },
                {
                  text: "Edit",
                  action: "edit",
                  onSelect: () => {},
                },
                {
                  text: "Delete",
                  action: "delete",
                  onSelect: () => {},
                },
                {
                  text: "Add new KPA",
                  action: "add",
                  onSelect: () => {
                    idp.childObjectType = "KPA";
                    setAddingObject(idp);
                    setModalOpen(true);
                  },
                },
              ]}
              iconType="vertical"
            />
          </div>
        </div>
        {isSetupBoxExpanded && (
          <>
            {/* <Divider title="KPAs" /> */}
            <div className="overflow-scroll">
              <div className="p-4">
                <ExpandableRowIDP
                  data={kpas}
                  showTableHeader={true}
                  setModalOpen={setModalOpen}
                  setAddingObject={setAddingObject}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <CreateIdpObject
          setModalOpen={setModalOpen}
          parentObject={addingObject}
        />
      </Modal>
    </div>
  );
};

export default IDPSetupBox;
