import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function SwitchLabels({ id, onChange, labelPlacement, label }) {
  return (
    <FormGroup>
      <FormControlLabel
        id={id}
        labelPlacement={labelPlacement}
        name={id}
        onChange={onChange}
        control={<Switch />}
        label={label}
      />
    </FormGroup>
  );
}
