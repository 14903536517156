import React from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { MOCK_API } from "assets";
import logoWhite from "assets/images/TasetiLogoWhite.svg";
import walterLogo from "assets/images/walterSisuluLogo.png";

import { useStateContext } from "application/store/ContextProvider";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, brandColors, organisation } =
    useStateContext();

  const sideBarImage = organisation.logo_url;

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const links = MOCK_API.sideBarLinks;

  const activeLinkStyles = `flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  bg-white/[0.7] text-primary-blue text-md m-2`;
  const normalLinkStyles =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-primary-blue font-semi-bold dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";
  return (
    <div
      className={`${
        activeMenu ? "md:w-[20vw]" : "w-0 translate-x-[-44px]"
      } fixed sidebar
            dark:bg-secondary-dark-bg bg-primary-green h-[90vh] mt-[7vh] ml-[3.5vh] rounded-sm overflow-hidden transition-all duration-500 z-50`}
    >
      <div
        className="h-screen 
      md:overflow-hidden overflow-auto 
      md:hover:overflow-auto p-4"
      >
        {activeMenu && (
          <>
              <button
                type="button"
                onClick={() => {
                  setActiveMenu((prev) => !prev);
                }}
                className="text-xl rounded-full p-3
                hover:bg-light-gray m-2 block absolute right-0 top-0"
              >
                <MdOutlineCancel color={brandColors.blue} />
              </button>

            <div className="flex w-full mt-4 items-center justify-center">
              <Link
                to="/"
                onClick={handleCloseSideBar}
                className="items-center
              flex bg-white rounded-full text-xl tracking-tight
              dark:text-white text-primary-blue p-2 mb-8 mt-8"
              >
                <img
                  src={walterLogo ?? sideBarImage ?? logoWhite}
                  className="h-32 rounded-full w-32"
                  alt="logo"
                />
              </Link>
            </div>

            <div>
              {links.map((item) => (
                <div key={item.title} className="ml-2">
                  <p className="text-primary-blue font-bold uppercase">
                    {item.title}
                  </p>
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.link}`}
                      key={`/${link.link}`}
                      onClick={handleCloseSideBar}
                      className={({ isActive }) =>
                        isActive ? activeLinkStyles : normalLinkStyles
                      }
                    >
                      {link.icon}
                      <span className="capitalize">{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))}
            </div>
            <div className="flex justify-center mb-24" />
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
