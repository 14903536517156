import React from "react";
import { Scaffold, TabPager } from "components";
import { MonitorIndicators } from "./view_components";
const tabs = [
  {
    header: { text: "Indicators" },
    content: <MonitorIndicators />,
  },
  {
    header: { text: "Budget" },
    content: <div>Glossary</div>,
  },
  {
    header: { text: "Projects" },
    content: <div>Glossary</div>,
  },
];

function Monitor() {
  return (
    <Scaffold moduleTitle="SDBIP" title="Monitor" mb="">
      <TabPager content={tabs} />
    </Scaffold>
  );
}

export default Monitor;
