import React from "react";
import Header from "../Heading";
import { useStateContext } from "application/store/ContextProvider";

function Scaffold({ children, title, moduleTitle, mb = "mb-4" }) {
  const { activeMenu } = useStateContext();
  return (
    <div className="h-full">
      <div className="overflow-scroll h-full pb-[40rem] mt-10 m-0 md:m-10 p-8 md:p-20 bg-white rounded-3xl rounded-b-none">
        <h2 className={`relative top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white  text-xl bg-primary-blue font-light ${mb}`}>
          {moduleTitle} <span className="font-semibold"> {title}</span>
        </h2>
        {/* <Header moduleTitle={moduleTitle} title={title} /> */}
        {children}
      </div>
    </div>
  );
}

export default Scaffold;
