import React from "react";
import { ICONS } from "assets";

const KPACard = ({ kpa }) => {
  function getCardIconAndStyles(rating) {
    if (rating >= 90) {
      return {
        icon: ICONS.graphUp,
        iconColor: "text-white",
        iconBg: "bg-rating-green",
      };
    } else if (rating >= 80) {
      return {
        icon: ICONS.graphEqual,
        iconColor: "text-white",
        iconBg: "bg-rating-yellow",
      };
    } else if (rating >= 70) {
      return {
        icon: ICONS.graphFluctuate,
        iconColor: "text-white",
        iconBg: "bg-rating-orange",
      };
    } else {
      return {
        icon: ICONS.graphDown,
        iconColor: "text-white",
        iconBg: "bg-rating-red",
      };
    }
  }

  function getFluctuationColor(rating_fluctuation) {
    if (rating_fluctuation > 0) {
      return "text-green-500";
    } else if (rating_fluctuation < 0) {
      return "text-red-500";
    } else {
      return "text-gray-500";
    }
  }

  const formatKPAForCard = (kpa) => {
    const { icon, iconColor, iconBg } = getCardIconAndStyles(
      kpa.performance_rating
    );
    const pcColor = getFluctuationColor(kpa.rating_fluctuation);
    return {
      title: kpa.name,
      amount: kpa.performance_rating,
      percentage: kpa.rating_fluctuation,
      pcColor,
      icon,
      iconColor,
      iconBg,
    };
  };

  let item = formatKPAForCard(kpa);
  return (
    <div
      key={item.title}
      className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg 
                      w-[40vw] md:w-40 min-h-[24vh] p-4 pt-9 rounded-2xl shadow-sm"
    >
      <button
        type="button"
        className={`text-2xl opacity-0.9
                        rounded-full p-4
                        hover:drop-shadow-xl ${item.iconBg} ${item.iconColor}`}
      >
        {<item.icon />}
      </button>
      <p className="mt-3">
        <span className="text-lg font-semibold">{item.amount}/100</span>
        <span className={`text-xs ${item.pcColor} ml-2`}>
          {item.percentage}%
        </span>
      </p>
      <p className="text-sm text-gray-400 mt-1">{item.title}</p>
    </div>
  );
};

export default KPACard;
