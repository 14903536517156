import React, { useEffect } from "react";
import { useStateContext } from "application/store/ContextProvider";
import { get } from "application/api";
import { useQuery } from "react-query";
import PlanCorporateScoreCardTable from "../components/PlanCorporateScoreCardTable";

const PlanScoreCards = () => {
  const { setActiveMenu } = useStateContext();
  const { user } = useStateContext();

  useEffect(() => {
    setActiveMenu(false);
  }, []);

  const {
    isLoading,
    error,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", user.organisation_id, null, user.token)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }
  console.log(activeIDP);
  return (
    <div className="pt-8">
      <h2 className="text-xl font-regular self-end text-gray-700 dark:text-gray-200">
        SDBIP Annual Corporate Scorecard
      </h2>
      <div className="w-full overflow-x-scroll">
        <PlanCorporateScoreCardTable
          headings={[
            // "Strategic Objective",
            "Key Focus Area",
            "Key Performance Indicator",
            "Base",
            "Year",
            "Q1",
            "Q2",
            "Q3",
            "Q4",
          ]}
          idpData={activeIDP}
          rowKeys={[
            { type: "string", name: "name" },

            // { type: "string", name: "strategic_objective" },

            { type: "array", name: "children" },
            {
              type: "child_array",
              parent_name: "children",
              name: "operational_objective",
            },
            {
              type: "child_child_array",
              parent_name: "children",
              name: "children",
            },
          ]}
          noDataText="No entry: Complete Setup"
        />
      </div>
    </div>
  );
};

export default PlanScoreCards;
