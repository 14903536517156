import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useStateContext } from "application/store/ContextProvider";
import { calculateRatingColor } from "application/utils";
import { exportMultipleComponentsToPdf } from "application/utils";
import { Button } from "@mui/base";

const MonitorKpaKfaTable = ({ idpData, fitView = false }) => {
  const { kpas } = idpData;
  console.log("kpas", kpas);
  const {
    brandPrimaryColors,
    brandColors,
    ratingColors,
    setModalOpen,
    setModalContent,
  } = useStateContext();
  const tableStyle = fitView
    ? { borderRadius: 0, width: "100%" }
    : { borderRadius: 0 };

  const headerCellStyle = {
    backgroundColor: brandPrimaryColors.blue ?? "#000",
    fontWeight: 600,
    fontSize: "1rem",
    border: 0.5,
    color: "#fff",
    p: 2,
  };

  if (!kpas) {
    return <div>Loading...</div>;
  }

  function prepareMetricsData({ kpas }) {
    const metricsData = [];
    let highestKpaChildrenCount = 0;

    kpas.forEach((kpa, index) => {
      const kpaBgColor = calculateRatingColor(
        kpa.performance_rating,
        100,
        ratingColors
      );
      metricsData.push([
        {
          short_code: kpa.short_code,
          bgColor: kpaBgColor,
        },
      ]);

      const { children } = kpa;
      if (!children) {
        return;
      }
      if (children.length > highestKpaChildrenCount) {
        highestKpaChildrenCount = children.length;
      }
      children.forEach((kfa) => {
        const kfaBgColor = calculateRatingColor(
          kfa.performance_rating,
          100,
          ratingColors
        );
        metricsData[index].push({
          short_code: kfa.short_code,
          bgColor: kfaBgColor,
        });
      });
    });
    return { metricsData, highestKpaChildrenCount };
  }

  const { metricsData, highestKpaChildrenCount } = prepareMetricsData({ kpas });
  console.log("metrics", metricsData);
  return (
    <div className="pdfExportIDP">
      <TableContainer sx={{ overflow: "scroll" }}>
        <Table sx={tableStyle} aria-label="a dense table">
          <TableHead sx={{ borderRight: 1, borderColor: "inherit" }}>
            <TableRow>
              <TableCell align="left" sx={headerCellStyle}>
                KPA
              </TableCell>
              <TableCell
                align="left"
                sx={headerCellStyle}
                colSpan={highestKpaChildrenCount}
              >
                Key Focus Areas
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderRight: 1, borderColor: "inherit" }}>
            {metricsData.map((metricRow, index) => {
              return (
                <TableRow key={index}>
                  {metricRow.map((metric, index) => {
                    return (
                      <TableCell
                        align="center"
                        sx={{
                          ...headerCellStyle,
                          backgroundColor: metric.bgColor,
                          color: "#fff",
                          border: 0.5,
                        }}
                        key={index}
                      >
                        {metric.short_code}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <button
        className="btn bg-primary-blue text-white p-2 border-r-2 ignorePdfExport"
        onClick={() => {
          exportMultipleComponentsToPdf("pdfExportIDP");
        }}
      >
        Export to PDF
      </button>
    </div>
  );
};

export default MonitorKpaKfaTable;
