import { baseUrl } from "../utils/ENV";
import { sendRequest } from "../utils/utils";

const getOrganisation = async (token, org_id, store) => {
  const url = `${baseUrl}/organisations`;
  const storeUpdateCallBack = store.setOrganisation;

  return sendRequest(
    "get",
    url,
    token,
    org_id,
    { org_id },
    storeUpdateCallBack
  );
};

const addOrganisation = async (token, org_id, data, store) => {
  const url = `${baseUrl}/organisations`;
  const storeUpdateCallBack = store.setOrganisation;

  return sendRequest("post", url, token, org_id, data, storeUpdateCallBack);
};

export { getOrganisation, addOrganisation };
