import React from "react";
import { useStateContext } from "application/store/ContextProvider";
import { useQuery } from "react-query";
import { get } from "application/api";
import CollapsibleMetricTable from "../components/SetupMetricTable";

const Align = () => {
  const { user } = useStateContext();
  const { organisation_id: orgID, token } = user;

  const {
    isLoading,
    error,
    data: policies,
  } = useQuery("policies", () => get("policies/kpas", orgID, null, token));

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  console.log(policies);
  return (
    <div>
      <h2 className="mt-10 mb-4 text-xl font-normal self-sart text-gray-700 dark:text-gray-200">
        National Policy Metrics:
      </h2>
      <div>Align KPAs to National, Provincial & District Policy</div>
      <div className="flex flex-row w-full items-center self-center justify-center h-fit">
        <div className="flex flex-row w-full flex-wrap gap-8 items-center justify-center md:items-start md:justify-start">
          {policies && policies.length > 0 ? (
            policies.map((policy, index) => (
              <div className="w-full">
                <CollapsibleMetricTable
                  key={index}
                  data={policy}
                  showAlignInput={true}
                />
              </div>
            ))
          ) : (
            <div>Add Policies in Master Setup</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Align;
