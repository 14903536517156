import React, { useState, createContext, useContext } from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  // application state
  const [activeMenu, setActiveMenu] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isClicked, setIsClicked] = useState(initialState);
  const [isLoggedin, setIsLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [user, setUser] = useState(null);

  // organisation
  const [employees, setEmployees] = useState([]);
  const [organisation, setOrganisation] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [financial_years, setFinancialYears] = useState([]);

  // IDP
  const [idps, setIDPs] = useState([]);
  const [kpas, setKPAs] = useState([]);
  const [kfas, setKFAs] = useState([]);

  // SDBIP
  const [kpis, setKPIs] = useState([]);

  // update state

  // organisation
  const addDepartments = (departments) => {
    setDepartments([...departments]);
  };

  const addFinancialYear = (financialYears) => {
    setFinancialYears([...financialYears]);
  };

  const addEmployees = (newEmployees) => {
    //console.log("employees", employees);
  };

  // IDP
  const updateIDPs = (newIDPs) => {
    setIDPs([...newIDPs]);
  };

  const updateKPAs = (newKPAs) => {
    setKPAs([...newKPAs]);
  };

  const updateKFAs = (newKFAs) => {
    setKFAs([...newKFAs]);
  };

  // SDBIP
  const updateKPIs = (newKPIs) => {
    setKPIs([...newKPIs]);
  };

  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: true });
  };
  const [screenSize, setScreenSize] = useState(undefined);

  const brandColors = {
    primary: "#C4D82F",
    blue: "#006884",
    blueAccent: "#EBF3F9",
    purple: "#e3165b",
    beige: "#F2E3A0",
    lightGray: "#F5F5F5",
    gold: "#F1D208",
    white: "#fff",
  };

  const brandPrimaryColors = {
    white: "#fff",
    purple: "#e3165b",
    primary: "#C4D82F",
    blue: "#006884",
    gold: "#F1D208",
    black: "#000",
  };

  const ratingColors = {
    green: "#C4D82F",
    yellow: "#FFD966",
    orange: "#FFB366",
    red: "#FF6666",
  };

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        isClicked,
        setIsClicked,
        handleClick,
        screenSize,
        setScreenSize,

        brandColors,
        brandPrimaryColors,
        ratingColors,

        isLoggedin,
        setIsLoggedIn,
        isRegistered,
        setIsRegistered,
        user,
        setUser,
        organisation,
        setOrganisation,
        departments,
        setDepartments,
        employees,
        setEmployees,
        addDepartments,
        addEmployees,
        financial_years,
        setFinancialYears,
        addFinancialYear,

        modalOpen,
        setModalOpen,
        modalContent,
        setModalContent,

        // IDP
        idps,
        updateIDPs,
        kpas,
        updateKPAs,
        kfas,
        updateKFAs,
        kpis,
        updateKPIs,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
