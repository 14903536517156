export { exportMultipleComponentsToPdf } from "./pdfExport";

export const calculateRatingColor = (current_value, target, ratingColors) => {
  if (target === 0) {
    target = 1;
  }
  if (current_value === 0) {
    target = 1;
  }
  const rating = Math.round((current_value / target) * 100);
  let color = "";
  if (rating >= 0 && rating <= 40) {
    color = ratingColors.red;
  } else if (rating >= 41 && rating <= 60) {
    color = ratingColors.orange;
  } else if (rating >= 61 && rating <= 80) {
    color = ratingColors.yellow;
  } else if (rating >= 81 && rating <= 100) {
    color = ratingColors.green;
  } else {
    color = ratingColors.green;
  }
  console.log("color", color);
  return color;
};
