import React, { useState } from "react";
import { Button, DropDown } from "components";
import { BsPlus } from "react-icons/bs";
import { IMAGES } from "assets";
import { CreateFinYear, CreateIDP, CreateSDBIP } from "./view_components";
import { Modal } from "components";
import { get, useApiMutation } from "application/api";
import { useStateContext } from "application/store/ContextProvider";
import { useQuery } from "react-query";

function Setup() {
  const store = useStateContext();
  const token = store.user.token;
  const orgID = store.organisation.id;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    type: "",
    data: {},
  });

  const {
    isLoading,
    error,
    data: idps,
  } = useQuery("idps", () => get("idps", orgID, null, token));

  const { isLoading: isLoadingFinYear, data: financial_years } = useQuery(
    "financial_years",
    () => get("financial_years", orgID, null, token)
  );

  if (isLoading || isLoadingFinYear) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  return (
    <div className="h-screen overflow-scroll pb-10 w-full">
      {/* <Header title="Setup" /> */}
      <div className="flex flex-row items-center  justify-center w-full h-full p-6 gap-6">
        <div className="flex flex-col items-center justify-center w-1/2 h-full gap-6">
          <div className="relative flex flex-col p-4 items-center justify-center shadow-md md:w-full h-1/2 bg-white rounded-md gap-3">
            <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white  text-xl bg-primary-blue font-light mb-8">
              <span className="font-semibold">Financial Years</span>
            </h2>
            <img src={IMAGES.calendarImg} alt="calendar" className="w-1/6" />
            <span className="flex w-8/12 overflow-scroll mb-4 max-h-24 scroll-bar flex-wrap items-center justify-center self-center text-sm text-slate-600 font-light">
              {financial_years
                ? financial_years.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className=" bg-slate-100 m-1 p-1 rounded-sm cursor-pointer hover:bg-slate-200"
                      >
                        <div>{item.name}</div>
                      </div>
                    );
                  })
                : "No Financial years found for this Orgaanization."}
            </span>
            <div className="absolute top-0 right-0 p-4">
              <Button
                text=""
                bgColor="hover:bg-slate-200 text-gray-600"
                icon={<BsPlus size={30} />}
                textSize={"sm"}
                onClick={() => {
                  setModalData({
                    type: "finYear",
                    data: {},
                  });
                  setModalOpen(true);
                }}
              />
            </div>
          </div>
          <div className="relative flex flex-col p-4 items-center justify-center shadow-md md:w-full h-1/2 bg-white rounded-md gap-3">
            <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white  text-xl bg-primary-blue font-light mb-8">
              <span className="font-semibold">IDP/SDBIP</span>
            </h2>{" "}
            <div className="absolute top-0 right-0 p-4">
              <Button
                text=""
                bgColor="hover:bg-slate-200 text-gray-600 float-right"
                icon={<BsPlus size={30} />}
                textSize={"sm"}
                onClick={() => {
                  setModalData({
                    type: "idp",
                    data: {},
                  });
                  setModalOpen(true);
                }}
              />
            </div>
            {idps ? (
              idps.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="flex flex-row justify-between bg-white border-1 gap-2 p-2 w-1/2 rounded-md cursor-pointer "
                  >
                    <div className="flex flex-col">
                      {item.name}
                      <span className="font-thin" style={{ fontSize: "13px" }}>
                        {item.description.substring(0, 30)}...
                      </span>
                    </div>
                    <Button
                      text=""
                      bgColor="hover:bg-slate-200 text-gray-600 float-right"
                      icon={<BsPlus size={20} />}
                      textSize={"sm"}
                      onClick={() => {
                        setModalData({
                          type: "sdbip",
                          data: item,
                        });
                        setModalOpen(true);
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div>No IDP found for this Organization. Add more.</div>
            )}
          </div>
        </div>
        <div className="flex relative flex-row items-center justify-center w-1/2 h-full gap-6">
          <h2 className="absolute top-0 left-0 rounded-br-md rounded-tl-md p-4 text-white  text-xl bg-primary-blue font-light mb-8">
            <span className="font-semibold">Glossary</span>
          </h2>
          <div className="flex flex-col items-center justify-center shadow-md w-full h-full bg-white rounded-md"></div>
        </div>
      </div>

      <Modal modalOpen={isModalOpen} setModalOpen={setModalOpen}>
        {modalData.type === "finYear" ? (
          <CreateFinYear setModalOpen={setModalOpen} />
        ) : modalData.type === "idp" ? (
          <CreateIDP setModalOpen={setModalOpen} />
        ) : modalData.type === "sdbip" ? (
          <CreateSDBIP setModalOpen={setModalOpen} idpID={modalData.data.id} />
        ) : (
          <div>Nothing to see here</div>
        )}
      </Modal>
    </div>
  );
}

export default Setup;
