import "../index.css";
import * as React from "react";
import { get } from "application/api";
import { useQuery } from "react-query";
import { useStateContext } from "application/store/ContextProvider";
import { Scaffold } from "components";
import SeniorsTable from "../myOrganisation/view_components/components/SeniorsTable";

const SeniorOfficials = () => {
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: users,
  } = useQuery("users", () =>
    get(
      "users",
      user.organisation_id,
      { seniority: "Senior Official" },
      user.token
    )
  );

  if (isLoading) {
    return (
      <div className="m-2 md:m10 p-2 md:p-10 bg-white rounded-3xl">
        Loading...
      </div>
    );
  }

  //console.log(users);

  return (
    <Scaffold title="Senior Officials">
      <SeniorsTable data={users} />
    </Scaffold>
  );
};
export default SeniorOfficials;
