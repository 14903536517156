import axios from "axios";
import { baseUrl } from "../utils/ENV.js";
// Login

const handleLogin = (email, org_code, password, store) => {
  // if (
  //   email === "mfundosapio@gmail.com" &&
  //   org_code === "WSLM" &&
  //   password === "password"
  // ) {
  //   alert("Login Successful");
  //   store.setUser({
  //     id: 1,
  //     name: "Khaya",
  //     last_name: "Gashi",
  //     email: "mfundosapio@gmail.com",
  //     phone: null,
  //     img_url:
  //       "https://www.wslm.gov.za/storage/images/39f2d02ab3cfa374e54c6fc6cfddea2a.jpg",
  //     organisation_id: 1,
  //     organisation_name: "Walter Sisulu Local Municipality",
  //     org_code: "WSLM",
  //     department_id: 1,
  //     role_id: 1,
  //     active: null,
  //     token: "170|liWbJgqNSkrdm0riCKNXwoC7ndIejzHt8CvZlEUO",
  //     terms_accepted: true,
  //     email_verified_at: "2023-12-28T12:10:17.000000Z",
  //   });

  //   localStorage.setItem(
  //     "token",
  //     "170|liWbJgqNSkrdm0riCKNXwoC7ndIejzHt8CvZlEUO"
  //   );

  //   store.setIsLoggedIn(true);
  //   store.setIsRegistered(true);
  //   return;
  // }
  axios
    .post(
      `${baseUrl}/login`,
      {
        email,
        org_code,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        store.setUser({
          ...response.data.user,
        });

        localStorage.setItem("token", response.data.token);

        if (response.data.user.active === 1) {
          store.setIsLoggedIn(true);
          store.setIsRegistered(true);
        } else {
          store.setIsLoggedIn(true);
        }
      } else {
        alert("error", response);
      }
    })
    .catch((error) => {
      alert("catch: " + error);
    });
};

export { handleLogin };
