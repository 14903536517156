import React from "react";

const Button = ({
  color,
  bgColor,
  text,
  textSize,
  icon,
  onClick,
  flat = false,
}) => {
  return (
    <button
      style={flat ? {} : { color, borderRadius: 4 }}
      className={`text-${textSize} ${bgColor} p-2 w-fit py-0 hover:drop-shadow-sm ignorePdfExport`}
      onClick={onClick}
    >
      <div className="flex flex-row items-center justify-center">
        {icon ? icon : null} {text}
      </div>
    </button>
  );
};

export default Button;
