import React from "react";

const Modal = ({ modalOpen, children }) => {
  return (
    <>
      {modalOpen && (
        <div className="fixed inset-0 z-50 w-full h-full flex items-center justify-center">
          <div className="p-4 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-primary-blue opacity-30"></div>
            </div>

            <div className="transform transition-all sm:my-8  sm:max-w-full sm:w-full">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
