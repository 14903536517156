import React, { useEffect, useState } from "react";
import { Scaffold } from "components";
import { ICONS } from "assets";

function Strategy() {
  const [statements, setStatements] = useState({
    vision: null,
    mission: null,
    values: null,
  });

  const [isEditing, setIsEditing] = useState({
    vision: false,
    mission: false,
    values: false,
  });

  useEffect(() => {
    setStatements({
      vision: "To be the best in the world at what we do.",
      mission:
        "To inspire and nurture the human spirit – one person, one cup and one neighborhood at a time.",
      values:
        "With our partners, our coffee and our customers at our core, we live these values:\n\n- Creating a culture of warmth and belonging, where everyone is welcome.\n- Acting with courage, challenging the status quo and finding new ways to grow our company and each other.\n- Being present, connecting with transparency, dignity and respect.\n- Delivering our very best in all we do, holding ourselves accountable for results.\n- We are performance driven, through the lens of humanity.",
    });
  }, []);

  const handleFieldChange = (type, e) => {
    setStatements({ ...statements, [type]: e });
  };

  const handleSave = (type) => {
    setIsEditing({ ...isEditing, [type]: false });
  };

  const EditIcon = (
    <ICONS.edit className="w-5 h-5 text-primary-blue dark:text-white cursor-pointer hover:text-blue-500" />
  );

  return (
    <Scaffold title="Strategy" moduleTitle="SDBIP">
      {/* Vision */}{" "}
      <div className="px-16">
        <div className="group mb-4 mt-12 flex flex-col align-middle justify-center">
          <label
            htmlFor="vision"
            className="block text-2xl font-medium mb-4 text-primary-blue self-center"
          >
            Vision
          </label>
          {!isEditing.vision ? (
            <div className="relative">
              <p className="p-2 border rounded-md">{statements.vision}</p>
              <div
                className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition duration-300"
                onClick={() => setIsEditing({ ...isEditing, vision: true })}
              >
                {EditIcon}
              </div>
            </div>
          ) : (
            <>
              <textarea
                id="vision"
                className="mt-1 p-2 w-full border rounded-md"
                rows="4"
                placeholder="Enter vision..."
                value={statements.vision}
                onChange={(e) => handleFieldChange("vision", e.target.value)}
              />
              <button
                className="bg-blue-500 w-[40%] md:w-[20%] self-center mt-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleSave("vision")}
              >
                Save
              </button>
            </>
          )}
        </div>

        {/* Mission */}

        <div className="group mb-4 mt-12 flex flex-col align-middle justify-center">
          <label
            htmlFor="mission"
            className="block text-2xl font-medium mb-4 text-primary-blue self-center"
          >
            Mission
          </label>
          {!isEditing.mission ? (
            <div className="relative">
              <p className="p-2 border rounded-md">{statements.mission}</p>
              <div
                className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition duration-300"
                onClick={() => setIsEditing({ ...isEditing, mission: true })}
              >
                {EditIcon}
              </div>
            </div>
          ) : (
            <>
              <textarea
                id="mission"
                className="mt-1 p-2 w-full border rounded-md"
                rows="4"
                placeholder="Enter mission..."
                value={statements.mission}
                onChange={(e) => handleFieldChange("mission", e.target.value)}
              />
              <button
                className="bg-blue-500 w-[40%] md:w-[20%] self-center mt-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleSave("mission")}
              >
                Save
              </button>
            </>
          )}
        </div>

        {/* Values */}

        <div className="group mb-4 mt-12 flex flex-col align-middle justify-center">
          <label
            htmlFor="values"
            className="block text-2xl font-medium mb-4 text-primary-blue self-center"
          >
            Values
          </label>
          {!isEditing.values ? (
            <div className="relative  w-full">
              <p className="p-2 border rounded-md">{statements.values}</p>
              <div
                className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition duration-300"
                onClick={() => setIsEditing({ ...isEditing, values: true })}
              >
                {EditIcon}
              </div>
            </div>
          ) : (
            <>
              <textarea
                id="values"
                className="mt-1 p-2 w-full border rounded-md"
                rows="4"
                placeholder="Enter values..."
                value={statements.values}
                onChange={(e) => handleFieldChange("values", e.target.value)}
              />
              <button
                className="bg-blue-500 w-[40%] md:w-[20%] self-center mt-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleSave("values")}
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>
      {/* Add a submit button or any other actions here */}
    </Scaffold>
  );
}

export default Strategy;
