import React from "react";
import { get } from "application/api";
import { useQuery } from "react-query";
import { useStateContext } from "application/store/ContextProvider";
import { FeatureTable, Scaffold } from "components";

const Employees = () => {
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: users,
  } = useQuery("users", () =>
    get(
      "users",
      user.organisation_id,
      {},
      user.token
    )
  );

  if (isLoading) {
    return (
      <div className="m-2 md:m10 p-2 md:p-10 bg-white rounded-3xl">
        Loading...
      </div>
    );
  }
  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
    },
    {
      field: "mobile",
      headerName: "Phone Number",
      width: 150,
      editable: true,
    },
    {
      field: "role_name",
      headerName: "Position",
      width: 200,
      editable: true,
    },
    {
      field: "department_name",
      headerName: "Department",
      width: 200,
      editable: true,
    },
  ];
  console.log(users);
  return (
    <Scaffold moduleTitle="" title="Employees">
      <FeatureTable rows={users} columns={columns} />
    </Scaffold>
  );
};

export default Employees;
