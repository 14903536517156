import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const seriesA = {
  data: [2, 3, 1, 4, 5],
  label: "Ward 70",
};
const seriesB = {
  data: [3, 1, 4, 2, 1],
  label: "Ward 84",
};
const seriesC = {
  data: [6, 5, 6, 5, 7],
  label: "Budget",
};
export default function StackedChart() {
  return (
    <BarChart
      xAxis={[
        { scaleType: "band", data: ["Jan", "Feb", "March", "April", "May"] },
      ]}
      width={500}
      height={300}
      series={[
        { ...seriesA, stack: "total" },
        { ...seriesB, stack: "total" },
        { ...seriesC },
      ]}
    />
  );
}
