import axios from "axios";
import { baseUrl } from "../utils/ENV";

// get financial_years
const getFinancialYears = async (token, org_id, store) => {
  const response = await axios
    .get(
      `${baseUrl}/financial_years`,
      {
        params: {
          id: org_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        store.addFinancialYear(response.data.data);
        ////console.log("store financial_years", store.financial_years);
        return response.data.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      //console.log(error.response);
      return error.response;
    });

  return response;
};

const createFinancialYear = async (token, org_id, data, store) => {
  const response = await axios
    .post(
      `${baseUrl}/financial_year`,
      {
        org_id,
        ...data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        store.addFinancialYear(response.data.financial_years);
      }
      return response;
    })
    .catch((error) => {
      return error;
    });

  return response;
};

export { getFinancialYears, createFinancialYear };
