import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useStateContext } from "application/store/ContextProvider";

export default function PlanCorporateScoreCardTable({
  headings,
  idpData,
  rowKeys,
  fitView = false,
  noDataText = "No Entry Found.",
}) {
  const { brandPrimaryColors, brandColors } = useStateContext();
  let { kpas } = idpData;

  const tableStyle = fitView
    ? { borderRadius: 0, width: "100%" }
    : { borderRadius: 0 };

  const headerCellStyle = {
    backgroundColor: brandPrimaryColors.blue ?? "#000",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#fff",
    p: 2,
  };

  const rowStyle = {
    "&:nth-of-type(odd)": {
      backgroundColor: brandPrimaryColors.lightGray ?? "#ccc",
    },
  };

  const cellStyle = {
    p: 0,
    py: 0,
  };

  const brandColorKeys = Object.values(brandPrimaryColors);
  console.log(brandColorKeys);

  // const kpiKeys = ['name', 'definition', 'objective', 'baseline', 'current_value', 'annual_target'];
  const kpiKeys = [
    "baseline",
    "annual_target",
    "quarter_1_target",
    "quarter_2_target",
    "quarter_3_target",
    "quarter_4_target",
  ];
  console.log(kpiKeys);
  // filter out kpas with no children
  kpas = kpas.filter((kpa) => kpa.children == null || kpa.children.length > 0);

  return (
    <TableContainer sx={{ overflow: "scroll" }}>
      <Table sx={tableStyle} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} sx={{ border: "none" }} />
            <TableCell
              colSpan={2}
              sx={{
                ...headerCellStyle,
                border: 0.5,
                borderColor: brandColors.lightGray,
              }}
            />
            <TableCell
              colSpan={6}
              align="center"
              sx={{
                ...headerCellStyle,
                border: 0.5,
                borderColor: brandColors.lightGray,
              }}
            >
              Targets
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} align="left" sx={{ border: "none" }} />
            {headings.map((heading) => (
              <TableCell
                align="center"
                colSpan={1}
                sx={{
                  ...headerCellStyle,
                  border: 0.5,
                  borderColor: brandColors.lightGray,
                }}
              >
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ borderRight: 1, borderColor: "inherit" }}>
          {kpas.map((kpa, index) => (
            <TableRow sx={{}}>
              <TableCell
                align="center"
                sx={{
                  backgroundColor:
                    brandColorKeys[(index % (brandColorKeys.length - 1)) + 1],
                  height: `${kpa.children ? 5 * kpa.children.length : ""}rem`,
                  color: "#fff",
                  // p: 1,
                  // py: 4,
                  width: "0.5rem",
                }}
                variant="head"
              >
                <Typography
                  variant="body2"
                  sx={{
                    textOrientation: "sideways",
                    writingMode: "vertical-lr",
                    transform: "rotate(180deg)",
                    fontWeight: 700,
                    textTransform: "uppercase",
                    align: "center",
                  }}
                >
                  {kpa.short_code}: {kpa.name}
                </Typography>
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  ...cellStyle,
                  height: "100%",
                  verticalAlign: "top",
                }}
              >
                {kpa.children &&
                  kpa.children.map((kfa) => (
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          verticalAlign: "center",
                          borderRight: 1,
                          borderColor: "inherit",
                          height: `${kfa.children.length * 5}rem`,
                          maxHeight: `${kfa.children.length * 5}rem`,
                          overflow: "scroll",
                        }}
                      >
                        {kfa.short_code}: {kfa.name}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  ...cellStyle,
                  height: "100%",
                  verticalAlign: "top",
                  p: 0,
                }}
              >
                {kpa.children &&
                  kpa.children.map(
                    (kfa) =>
                      kfa.children &&
                      kfa.children.map((kpi, index) => (
                        <TableRow
                          sx={{ p: 0, borderRight: 1, borderColor: "inherit" }}
                          key={index}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              overflow: "scroll",
                              height: "5rem",
                              clipRule: "inherit",
                            }}
                          >
                            {kpi.name}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
              </TableCell>

              {kpiKeys.map((key) => (
                <TableCell
                  align="left"
                  sx={{ height: "100%", verticalAlign: "top", p: 0 }}
                >
                  {kpa.children &&
                    kpa.children.map(
                      (kfa) =>
                        kfa.children &&
                        kfa.children.map((kpi) => (
                          <TableRow
                            sx={{
                              height: `${100 / kpiKeys.length}%`,
                              borderRight: 1,
                              borderColor: "inherit",
                            }}
                          >
                            <TableCell align="center" sx={{ height: "5rem" }}>
                              {kpi[key]}
                            </TableCell>
                          </TableRow>
                        ))
                    )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
