import React from "react";
import { Scaffold, TabPager } from "components";
import PlanObjectives from "./view_components/tabs/PlanObjectives";

const tabs = [
  {
    header: { text: "Objectives" },
    content: <PlanObjectives />,
  },
  {
    header: { text: "Glossary" },
    content: <div>Glossary</div>,
  },
];

function Plan() {
  return (
    <Scaffold moduleTitle="IDP" title="Plan" mb="">
      <TabPager content={tabs} />
    </Scaffold>
  );
}

export default Plan;
