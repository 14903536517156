import React from "react";
import { Scaffold, TabPager } from "components";
import { PerformanceAgreement } from "./view_components";
const tabs = [
  {
    header: { text: "Performance Agreements" },
    content: <PerformanceAgreement />,
  },
  {
    header: { text: "Projects" },
    content: <div>Projects</div>,
  },
  {
    header: { text: "Align" },
    content: <div>Align</div>,
  },
  {
    header: { text: "Financial Plan" },
    content: <div>Outline Financial Plan</div>,
  },
  {
    header: { text: "User Permissions" },
    content: <div>User Permissions</div>,
  },
];

function SetupIDP() {
  return (
    <>
      <Scaffold moduleTitle="SDBIP" title="Setup" mb="">
        <TabPager content={tabs} />
      </Scaffold>
    </>
  );
}

export default SetupIDP;
