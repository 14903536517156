import React from "react";
import Scaffold from "components/layout/Scaffold";
import MonitorKpaKfaTable from "./view_components/components/MonitorKpaKfaTable";
import { useQuery } from "react-query";
import { get } from "application/api";
import { useStateContext } from "application/store/ContextProvider";

function Monitor() {
  const { user } = useStateContext();
  const {
    isLoading,
    error,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", user.organisation_id, null, user.token)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }
  console.log(activeIDP);
  return (
    <Scaffold moduleTitle="IDP" title="Monitor">
      Monitor Metric Performance Rating
      <MonitorKpaKfaTable idpData={activeIDP} />
    </Scaffold>
  );
}

export default Monitor;
