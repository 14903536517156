import React from "react";
import { Scaffold } from "components";

function Manage() {
  return (
    <Scaffold moduleTitle="SDBIP" title="Manage" mb="">
      Manage
    </Scaffold>
  );
}

export default Manage;
