import React from "react";
import { Scaffold, TabPager } from "components";
import { Indicators, SetupProjects } from "./view_components";
// import Align from "./view_components/tabs/SetupAlign";

const tabs = [
  {
    header: { text: "Indicators" },
    content: <Indicators />,
  },
  {
    header: { text: "Projects" },
    content: <SetupProjects />,
  },
  {
    header: { text: "Align" },
    content: <div>Align</div>,
  },
  {
    header: { text: "Financial Plan" },
    content: <div>Outline Financial Plan</div>,
  },
  {
    header: { text: "User Permissions" },
    content: <div>User Permissions</div>,
  },
];

function SetupIDP() {
  return (
    <>
      <Scaffold moduleTitle="SDBIP" title="Setup" mb="">
        <TabPager content={tabs} />
      </Scaffold>
    </>
  );
}

export default SetupIDP;
