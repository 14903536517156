import React from "react";
import { TableSimple } from "components";
import { useStateContext } from "application/store/ContextProvider";
import { useQuery } from "react-query";
import { get } from "application/api";
import { InnerPie } from "components";
import { exportMultipleComponentsToPdf } from "application/utils";

const PlanObjectives = () => {
  const { user } = useStateContext();
  const { organisation_id, token } = user;

  const {
    isPoliciesLoading,
    policiesError,
    data: policies,
  } = useQuery("policies", () =>
    get("policies/kpas", organisation_id, null, token)
  );
  const {
    isLoading,
    error,
    data: activeIDP,
  } = useQuery("activeIDP", () =>
    get("idps/active", organisation_id, null, token)
  );

  if (isLoading || isPoliciesLoading) {
    return <div>Loading...</div>;
  }
  if (error || policiesError) {
    return <div>An error has occurred: {error.message}</div>;
  }

  console.log(activeIDP);

  return (
    <div className="mt-4 pdfExport">
      <div className="mt-4 flex flex-row justify-center">
        Municipal Key Performance Areas and Strategic Objectives
      </div>
      <TableSimple
        headings={["Key Performance Area", "Strategic Objective"]}
        rowKeys={[
          { type: "string", name: "name" },
          { type: "string", name: "strategic_objective" },
        ]}
        rowData={activeIDP.children}
        fitView={true}
      />
      <div className="mt-8 flex flex-row justify-center">
        Municipal Key Performance Areas Aligned to Key Focus Areas
      </div>
      <TableSimple
        headings={[
          "Key Performance Area",
          "Strategic Objective",
          "Key Focus Area",
          "Operational Objective",
        ]}
        rowData={activeIDP.children}
        rowKeys={[
          { type: "string", name: "name" },
          { type: "string", name: "strategic_objective" },
          { type: "array", name: "children" },
          {
            type: "child_array",
            parent_name: "children",
            name: "operational_objective",
          },
        ]}
        noDataText="No entry: Complete Setup"
      />

      {policies &&
        policies.map((policy, index) => (
          <>
            <div className="mt-8 flex flex-row justify-center" key={index}>
              {policy.name} Aligned to Municipal Key Performance Areas
            </div>
            <TableSimple
              headings={[policy.metric_name, `${user.org_code} KPAs`]}
              rowKeys={[
                { type: "string", name: "name" },
                { type: "array", name: "aligned_kpas" },
              ]}
              noDataText="No Entry: Complete IDP Setup"
              rowData={policy.children}
              fitView={true}
            />
          </>
        ))}
      <button
        className="btn bg-primary-blue text-white mt-8 p-2 border-r-2 ignorePdfExport"
        onClick={() => {
          exportMultipleComponentsToPdf("pdfExport");
        }}
      >
        Export to PDF
      </button>

      <InnerPie />
    </div>
  );
};

export default PlanObjectives;
